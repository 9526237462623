import ApiAdminService from './ApiAdminService';
import ApiService from './ApiService';
import AuthService from './AuthService';

class ApiSelector {

  constructor() {
    if( AuthService.getCurrentUser() && AuthService.getCurrentUser().type === 0 ) {
      return ApiAdminService;
    }else{
      return ApiService;
    }
  }

}

export default new ApiSelector();
