import React, { useEffect, useState } from 'react';

import PidInput from '../components/PidInput';
import BirthdaySelect from '../components/BirthdaySelect';
import CountrySelect from '../components/CountrySelect';

import AuthService from '../services/AuthService';

function MyAccount(props){

  const setBreadcrumbs = props.setBreadcrumbs;
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [profileData, setProfileData] = useState({});

  const isClient = AuthService.getCurrentUser().type === 1; // SOLO CLIENTE
  const isInvestor = AuthService.getCurrentUser().type === 2; // SOLO INVERSOR

  useEffect(() => {
    setBreadcrumbs([{title:'Inicio', to:'/home'}, {title:'Mi cuenta'}]);

    let isMounted = true;
    
    if( isMounted ){
      // get_profile_data();
    }
    
    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_profile_data = () => {
    // ApiService.loans()
    //   .then(
    //     (data) => {
    //       // console.log(data);
    //       setIsLoadingData(false);
    //       setReqLoanList(data.results);
    //       setReqLoanListCount(data.count);
    //     }, error => {
    //       // console.log(error);
    //       setReqLoanListCount(0);
    //       setIsLoadingData(false);
    //       NotificationManager.error(
    //         'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
    //     });

  }

  const handleChange = (e) => {
    const {id , value} = e.target;
    setProfileData(prevState => ({
      ...prevState,
      [id] : value
    }));
  };

  return (
    <React.Fragment>
      
      <div className="container-fluid acontainer-fluid-form">

        <h5 className="pt-2">Mi cuenta</h5>

        <div className="container p-0" style={{ minWidth: '800px', width: '800px' }}>

          <div className="row">
            <div className="col">
              <p 
                className="alert-warning border-warning border rounded p-2"
                style={{ fontSize: '14px' }}
                >Debes completar tu perfil para comenzar a operar en la plataforma.</p>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">

              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a 
                    className="nav-link active position-relative text-bantex" 
                    data-bs-toggle="tab" data-bs-target="#access"
                    href="#access">
                    Datos de acceso
                    {/*<span className="position-absolute top-0 start-0 translate-middle p-1 bg-danger border border-light rounded-circle">
                      <span className="visually-hidden">New alerts</span>
                    </span>*/}
                    </a>
                </li>
                <li className="nav-item">
                  <a 
                    className="nav-link position-relative text-bantex" 
                    data-bs-toggle="tab" data-bs-target="#personal" 
                    href="#personal">
                    Datos personales
                    {/*<span className="position-absolute top-0 start-0 translate-middle p-1 bg-danger border border-light rounded-circle">
                      <span className="visually-hidden">New alerts</span>
                    </span>*/}
                    </a>
                </li>
                <li className="nav-item">
                  <a 
                    className="nav-link position-relative text-bantex" 
                    data-bs-toggle="tab" data-bs-target="#parent" 
                    href="#parent">Pariente cercano</a>
                </li>
                <li className="nav-item">
                  <a 
                    className="nav-link position-relative text-bantex" 
                    data-bs-toggle="tab" data-bs-target="#work" 
                    href="#work">Datos laborales</a>
                </li>

                <li className="nav-item">
                  <a 
                    className="nav-link position-relative text-bantex" 
                    data-bs-toggle="tab" data-bs-target="#user_profile" 
                    href="#user_profile">Perfil de cliente</a>
                </li>
              </ul>

              <div className="tab-content">
                
                <div 
                  className="tab-pane px-2 pt-4 show active bg-white border-end border-start border-bottom" 
                  id="access" 
                  role="tabpanel" aria-labelledby="tab-access">
                  <MyAccountAccessForm 
                    //handleChange={handleChange}
                    />
                </div>
                
                <div 
                  className="tab-pane px-2 pt-4 bg-white border-end border-start border-bottom" 
                  id="personal" 
                  role="tabpanel" aria-labelledby="tab-personal">
                  <MyAccountPersonalForm
                    handleChange={handleChange}
                    />
                </div>
                
                <div 
                  className="tab-pane px-2 pt-4 bg-white border-end border-start border-bottom" 
                  id="parent" 
                  role="tabpanel" aria-labelledby="tab-parent">
                  <MyAccountParentForm
                    handleChange={handleChange}
                    />
                </div>
                
                <div 
                  className="tab-pane px-2 pt-4 bg-white border-end border-start border-bottom" 
                  id="work" 
                  role="tabpanel" aria-labelledby="tab-work">
                  <MyAccountWorkForm
                    handleChange={handleChange}
                    />
                </div>
                
                <div 
                  className="tab-pane px-2 pt-4 bg-white border-end border-start border-bottom" 
                  id="user_profile" 
                  role="tabpanel" aria-labelledby="tab-user_profile">
                  {isInvestor && (
                    <MyAccountInvestorForm
                      handleChange={handleChange}
                      />
                  )}

                  {isClient && (
                    <MyAccountClientForm
                      handleChange={handleChange}
                      />
                  )}
                </div>
              </div>

            </div>
          </div>

          <div className="row justify-content-end mb-3">
            <div className="col-auto">
              <input 
                type="password" 
                className="form-control" 
                id="password" 
                // value={registerForm.password}
                // onChange={handleChange}
                aria-describedby="passwdHelp" 
                placeholder="Contrase&ntilde;a actual"
                required 
                />
            </div>

            <div className="col-auto">
              <button 
                type="button" 
                className="btn bg-bantex text-white"
                >Guardar cambios</button>
            </div>
          </div>

        </div>
      </div>

    </React.Fragment>
  );
}

function MyAccountClientForm(props) {

  const registerForm = {};
  const handleChange = props.handleChange;

  const [hasMortgage, setHasMortgage] = useState(false);
  const [hasVehicle, setHasVehicle] = useState(false);
  const [hasVehicleMortgage, setHasVehicleMortgage] = useState(false);

  return (
    <React.Fragment>

      <div className="row mb-3">
        <div className="col bg-bantex text-white mx-1">
          <span>Situacion financiera</span>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-4">
          <div className="form-floating mb-2">
            <select className="form-select" id="property_type" placeholder="Tipo de vivienda">
              <option>Alquilada</option>
              <option>Vive con los padres</option>
              <option>Otra</option>
            </select>
            <label htmlFor="property_type">Tipo de vivienda</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="property_value" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Valor de la propiedad"
              required 
              />
            <label htmlFor="property_value">Valor de la propiedad</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md-auto">
          <span>Hipoteca</span>
        </div>
        <div className="col-md-auto">
          <div className="btn-group" role="group" aria-label="Hipoteca">
            <input 
              type="radio" 
              className="btn-check btn-check-bantex" 
              name="property_mortgage" 
              id="property_mortgageyes" 
              onChange={(e) => setHasMortgage(true)}
              value="1" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="property_mortgageyes">Si</label>

            <input 
              type="radio" 
              className="btn-check btn-check-bantex" 
              name="property_mortgage" 
              id="property_mortgageno" 
              value="2" autoComplete="off" 
              onChange={(e) => setHasMortgage(false)}
              defaultChecked />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="property_mortgageno">No</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="property_mortgage_bank" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Banco"
              required 
              disabled={!hasMortgage}
              />
            <label htmlFor="property_mortgage_bank">Banco</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="property_mortgage_balance" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Saldo"
              required 
              disabled={!hasMortgage}
              />
            <label htmlFor="property_mortgage_balance">Saldo</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="property_mortgage_monthly" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Pago mensual"
              required 
              disabled={!hasMortgage}
              />
            <label htmlFor="property_mortgage_monthly">Pago mensual</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col bg-bantex text-white mx-1">
          <span>Vehiculo</span>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        
        <div className="col-md-auto">
          <span>Vehiculo</span>
        </div>
        
        <div className="col-md">
          <div className="btn-group" role="group" aria-label="Vehiculo">
            <input 
              type="radio" 
              className="btn-check btn-check-bantex" 
              name="vehicle" 
              id="vehicleyes" 
              onChange={(e) => setHasVehicle(true)}
              value="1" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="vehicleyes">Si</label>

            <input 
              type="radio" 
              className="btn-check btn-check-bantex" 
              name="vehicle" 
              id="vehicleno" 
              onChange={(e) => {setHasVehicle(false); setHasVehicleMortgage(false)}}
              defaultChecked
              value="2" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="vehicleno">No</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="vehicle_year" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="A&ntilde;o"
              required 
              disabled={!hasVehicle}
              />
            <label htmlFor="vehicle_year">A&ntilde;o</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="vehicle_plate" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Placa"
              required 
              disabled={!hasVehicle}
              />
            <label htmlFor="vehicle_plate">Placa</label>
          </div>
        </div>

      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md-auto">
          <span>Financiado</span>
        </div>

        <div className="col-md">
          <div className="btn-group" role="group" aria-label="Vehiculo financiado">
            <input 
              type="radio" 
              className="btn-check btn-check-bantex" 
              name="vehicle_mortgage" 
              id="vehicle_mortgageyes" 
              checked={hasVehicleMortgage}
              onChange={(e) => setHasVehicleMortgage(true)}
              disabled={!hasVehicle}
              value="1" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="vehicle_mortgageyes">Si</label>

            <input 
              type="radio" 
              className="btn-check btn-check-bantex" 
              name="vehicle_mortgage" 
              id="vehicle_mortgageno" 
              checked={!hasVehicleMortgage}
              onChange={(e) => setHasVehicleMortgage(false)}
              disabled={!hasVehicle}
              value="2" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="vehicle_mortgageno">No</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="vehicle_mortgage_bank" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Banco"
              required 
              disabled={!hasVehicleMortgage}
              />
            <label htmlFor="vehicle_mortgage_bank">Banco</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="vehicle_mortgage_monthly" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Cuota"
              required 
              disabled={!hasVehicleMortgage}
              />
            <label htmlFor="vehicle_mortgage_monthly">Cuota</label>
          </div>
        </div>


        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="vehicle_mortgage_balance" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Saldo financiamiento"
              required 
              disabled={!hasVehicleMortgage}
              />
            <label htmlFor="vehicle_mortgage_balance">Saldo financiamiento</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col bg-bantex text-white mx-1">
          <span>Tarjetas de credito</span>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="cc1_bank" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Emisor"
              required 
              />
            <label htmlFor="cc1_bank">Emisor</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="cc1_limit" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Limite"
              required 
              />
            <label htmlFor="cc1_limit">Limite</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="cc1_balance" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Saldo"
              required 
              />
            <label htmlFor="cc1_balance">Saldo</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="cc1_monthly" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Cuota"
              required 
              />
            <label htmlFor="cc1_monthly">Cuota</label>
          </div>
        </div>
      </div>

      <hr />

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="cc2_bank" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Emisor"
              required 
              />
            <label htmlFor="cc2_bank">Emisor</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="cc2_limit" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Limite"
              required 
              />
            <label htmlFor="cc2_limit">Limite</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="cc2_balance" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Saldo"
              required 
              />
            <label htmlFor="cc2_balance">Saldo</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="cc2_monthly" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Cuota"
              required 
              />
            <label htmlFor="cc2_monthly">Cuota</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col bg-bantex text-white mx-1">
          <span>Credito personal</span>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="loan_bank" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Banco"
              required 
              />
            <label htmlFor="loan_bank">Banco</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="loan_balance" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Saldo"
              required 
              />
            <label htmlFor="loan_balance">Saldo</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="loan_monthly" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Cuota mensual"
              required 
              />
            <label htmlFor="loan_monthly">Cuota mensual</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col bg-bantex text-white mx-1">
          <span>Otros</span>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="other_investments" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Otras inversiones"
              required 
              />
            <label htmlFor="other_investments">Otras inversiones</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md">
          <span>&iquest;Ha tenido problemas financieros en los ultimos 3 a&ntilde;os?</span>
        </div>
        <div className="col-md-auto">
          <div className="btn-group" role="group" aria-label="Problemas financieros">
            <input type="radio" className="btn-check btn-check-bantex" name="other_financial_problem" id="other_financial_problemyes" value="1" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="other_financial_problemyes">Si</label>

            <input type="radio" className="btn-check btn-check-bantex" name="other_financial_problem" id="other_financial_problemno" value="2" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="other_financial_problemno">No</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md">
          <span>&iquest;Algun juicio/fallo judicial en su contra?</span>
        </div>
        <div className="col-md-auto">
          <div className="btn-group" role="group" aria-label="Juicio o fallo en su contra">
            <input type="radio" className="btn-check btn-check-bantex" name="other_law_problem" id="other_law_problemyes" value="1" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="other_law_problemyes">Si</label>

            <input type="radio" className="btn-check btn-check-bantex" name="other_law_problem" id="other_law_problemno" value="2" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="other_law_problemno">No</label>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

function MyAccountInvestorForm(props) {

  const registerForm = {};
  const handleChange = props.handleChange;

  return (
    <React.Fragment>
      <div className="row mb-3 align-items-center">
        <div className="col-md">
          <span>&iquest;Esta usted acusado o procesado por algun tipo de delito?</span>
        </div>
        <div className="col-md-auto">
          <div className="btn-group" role="group" aria-label="Perfil de cliente - Pregunta 1">
            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_1" id="profile_q_1yes" value="1" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_1yes">Si</label>

            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_1" id="profile_q_1no" value="2" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_1no">No</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md">
          <span>&iquest;Es usted o esta usted relacionado (familia o socios de negocios) de alguna forma con una persona publicamente expuesta?</span>
        </div>
        <div className="col-md-auto">
          <div className="btn-group" role="group" aria-label="Perfil de cliente - Pregunta 2">
            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_2" id="profile_q_2yes" value="1" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_2yes">Si</label>

            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_2" id="profile_q_2no" value="2" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_2no">No</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md">
          <span>&iquest;Eres ciudadano o residente de Panama?</span>
        </div>
        <div className="col-md-auto">
          <div className="btn-group" role="group" aria-label="Perfil de cliente - Pregunta 3">
            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_3" id="profile_q_3yes" value="1" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_3yes">Si</label>

            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_3" id="profile_q_3no" value="2" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_3no">No</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md">
          <span>&iquest;Eres ciudadano o residente de los Estados Unidos?</span>
        </div>
        <div className="col-md-auto">
          <div className="btn-group" role="group" aria-label="Perfil de cliente - Pregunta 4">
            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_4" id="profile_q_4yes" value="1" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_4yes">Si</label>

            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_4" id="profile_q_4no" value="2" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_4no">No</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md">
          <span>Cuentanos sobre tu fuente de ingreso.</span>
        </div>
        <div className="col-md-auto">
          <div className="btn-group" role="group" aria-label="Perfil de cliente - Pregunta 5">
            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_5" id="profile_q_51" value="1" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_51">Asalariado</label>

            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_5" id="profile_q_52" value="2" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_52">Negocio</label>

            <input type="radio" className="btn-check btn-check-bantex" name="profile_q_5" id="profile_q_53" value="3" autoComplete="off" />
            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_53">Retirado</label>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

function MyAccountWorkForm(props) {

  const registerForm = {};
  const handleChange = props.handleChange;

  return (
    <React.Fragment>

      <div className="row mb-3 align-items-center">
        <label htmlFor="borndate" className="col-md-6 form-col-label">Fecha de ingreso</label>
        <div className="col-md">
          <div className="input-group">
            <select className="form-select">
              <option value="1">Agosto</option>
            </select>
            <select className="form-select">
              <option value="1">18</option>
            </select>
            <select className="form-select">
              <option value="1">1984</option>
            </select>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-7">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="work_place" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Lugar de trabajo"
              required />
            <label htmlFor="work_place">Lugar de trabajo</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="work_position" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Puesto"
              required />
            <label htmlFor="work_position">Puesto</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="work_salary" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Salario actual"
              required />
            <label htmlFor="work_salary">Salario actual</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="work_tel" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Telefono"
              required />
            <label htmlFor="work_tel">Telefono</label>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

function MyAccountParentForm(props) {

  const registerForm = {};
  const handleChange = props.handleChange;

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col-md-7">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="parent_other_name" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Nombre completo"
              required />
            <label htmlFor="parent_other_name">Nombre completo</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="parent_other_desc" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Parentesco"
              required />
            <label htmlFor="parent_other_desc">Parentesco</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="parent_other_address" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Direccion de residencia"
              required />
            <label htmlFor="parent_other_address">Direccion de residencia</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="parent_other_tel_home" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Telefono de residencia"
              required />
            <label htmlFor="parent_other_tel_home">Telefono de residencia</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="parent_other_tel_office" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Telefono oficina"
              required />
            <label htmlFor="parent_other_tel_office">Telefono oficina</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="tel_other" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Otro telefono"
              required />
            <label htmlFor="tel_other">Otro telefono</label>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

function MyAccountPersonalForm(props) {

  const registerForm = {};
  const handleChange = props.handleChange;

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col-md-3">
          <div className="form-floating mb-2">
            <select 
              className="form-select" 
              id="gender" 
              value={registerForm.gender}
              onChange={handleChange}
              placeholder="Sexo">
              <option value="1">Hombre</option>
              <option value="2">Mujer</option>
            </select>
            <label htmlFor="gender">Sexo</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="name" 
              value={registerForm.name}
              onChange={handleChange}
              placeholder="Nombre/s"
              required 
              />
            <label htmlFor="name">Nombre/s</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-6">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="lastname_first" 
              value={registerForm.lastname_first}
              onChange={handleChange}
              placeholder="Primer Apellido"
              required 
              />
            <label htmlFor="lastname_first">Primer Apellido</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="lastname_second" 
              value={registerForm.lastname_second}
              onChange={handleChange}
              placeholder="Segundo Apellido"
              required 
              />
            <label htmlFor="lastname_second">Segundo Apellido</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md-4">
          <div className="form-floating mb-2">
            <select 
              className="form-select" 
              id="pid_type" 
              placeholder="Tipo de Identificacion"
              onChange={handleChange}
              value={registerForm.pid_type} 
              >
              <option value="1">Cedula</option>
              <option value="2">Pasaporte</option>
              <option value="3">Residencia</option>
            </select>
            <label htmlFor="pid_type">Tipo de Identificacion</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <PidInput 
              id="pid" 
              value={registerForm.pid}
              onChange={handleChange}
              pidType={registerForm.pid_type}
              required={true}
              />
            <label htmlFor="pid">Nro. de Identificacion</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <label htmlFor="borndate" className="col-md-6 form-col-label">Fecha de nacimiento</label>
        <div className="col-md">
          <BirthdaySelect
            id="birthdate"
            onChange={handleChange}
            value={registerForm.birthdate} />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
          
            <CountrySelect 
              value={registerForm.country}
              handleChange={handleChange}
              />

            <label htmlFor="country">Nacionalidad</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <select 
              className="form-select" 
              id="marital_status" 
              value={registerForm.marital_status}
              onChange={handleChange}
              placeholder="Estado Civil"
              >
              <option value="1">Soltero</option>
              <option value="2">Casado</option>
              <option value="3">Divorciado</option>
              <option value="4">Viudo</option>
            </select>
            <label htmlFor="marital_status">Estado Civil</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="address" 
              value={registerForm.address}
              onChange={handleChange}
              placeholder="Direccion de residencia"
              required />
            <label htmlFor="address">Direccion de residencia</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="tel_home" 
              value={registerForm.tel_home}
              onChange={handleChange}
              placeholder="Telefono de residencia"
              required />
            <label htmlFor="tel_home">Telefono de residencia</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="tel_mobile" 
              value={registerForm.tel_mobile}
              onChange={handleChange}
              placeholder="Telefono celular"
              required />
            <label htmlFor="tel_mobile">Telefono celular</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="tel_manage" 
              value={registerForm.tel_manage}
              onChange={handleChange}
              placeholder="Telefono para gestion"
              required />
            <label htmlFor="tel_manage">Telefono para gestion</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-7">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="parent_name" 
              value={registerForm.parent_name}
              onChange={handleChange}
              placeholder="Nombre completo del conyuge"
              required />
            <label htmlFor="parent_name">Nombre completo del conyuge</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="parent_pid" 
              value={registerForm.parent_pid}
              onChange={handleChange}
              placeholder="Nro. de Identificacion"
              required />
            <label htmlFor="parent_pid">Nro. de Identificacion</label>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

function MyAccountAccessForm(props) {

  const registerForm = {};
  const handleChange = props.handleChange;

  return (
    <React.Fragment>
      <div className="row mb-3">

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="email" 
              className="form-control" 
              id="email" 
              value={registerForm.email}
              onChange={handleChange}
              placeholder="Correo Electronico"
              required 
              />
            <label htmlFor="email">Correo Electronico</label>
            <div className="invalid-feedback">Por favor ingrese su direccion de correo electronico.</div>
          </div>
        </div>

      </div>
      <div className="row mb-3">

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="username" 
              value={registerForm.username}
              onChange={handleChange}
              aria-describedby="usernameHelp" 
              placeholder="Nombre de usuario"
              required 
              />
            <label htmlFor="username">Nombre de usuario</label>
            <div className="invalid-feedback">Por favor ingrese un nombre de usuario valido.</div>
              {/*<div id="usernameHelp" className="form-text">
                Minimo 8 caracteres. Solo se admiten letras (a-zA-Z) y numeros (0-9)
              </div>*/}
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col bg-bantex text-white mx-1">
          <span>Cambiar contrase&ntilde;a</span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating mb-2">
            <input 
              type="password" 
              className="form-control" 
              id="password" 
              value={registerForm.password}
              onChange={handleChange}
              aria-describedby="passwdHelp" 
              placeholder="Contrase&ntilde;a"
              required 
              />
            <label htmlFor="password">Nueva contrase&ntilde;a</label>
            <div className="invalid-feedback">Por favor ingrese una contrase&ntilde;a valida.</div>
              <div id="passwdHelp" className="form-text">
                Debe incluir uno o mas numeros (0-9)
              </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-floating mb-2">
            <input 
              type="password" 
              className="form-control" 
              id="passwordR" 
              value={registerForm.passwordR}
              onChange={handleChange}
              aria-describedby="passwdHelpR" 
              placeholder="Repetir Contrase&ntilde;a"
              required 
              />
            <label htmlFor="passwordR">Repetir nueva contrase&ntilde;a</label>
            <div className="invalid-feedback">La contrase&ntilde;a ingresada no coincide.</div>
              <div id="passwdHelpR" className="form-text">
                Por favor, ingrese nuevamente su contrase&ntilde;a
              </div>
          </div>
        </div>
      </div>
      
    </React.Fragment>
  );
}

export default MyAccount;