import React, {useState, useEffect, useMemo} from 'react';
import ReactModal from 'react-modal';
import { NotificationManager } from 'react-notifications';

import ApiService from '../../services/ApiService';
import StatusEnum from '../../page/StatusEnum';

import TableBasic from '../../components/TableBasic';
import LoanAmount from '../../components/LoanAmount';
import LoanDate from '../../components/LoanDate';
import LoanModal from '../../components/LoanModal';
import LoanStatus from '../../components/LoanStatus';
import LoanRequestReason from '../../components/LoanRequestReason';

ReactModal.setAppElement('#root');

function InvestedLoans(props){

  const setBreadcrumbs = props.setBreadcrumbs;
  
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [loanViewId, setLoanViewId] = useState(null);
  const [investedLoansList, setInvestedLoansList] = useState([]);
  const [investedLoansCount, setInvestedLoansCount] = useState(0);
  const [openLoanModal, setOpenLoanModal] = useState(false);
  const [doRefresh, setDoRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const showStatus = [StatusEnum.APPROVED_CLIENT, StatusEnum.INVEST_COMPLETED];

  const modalStyles = {
    content: {
      minWidth: '700px',
      maxWidth: '700px',
      width: '80%',
      height: '85%',
      top: '50%',
      left: '50%',
      // right: '10%',
      // bottom: '10%',
      // marginRight: '50%',
      // marginBottom: '-30%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {zIndex: 1000}
  };

  useEffect( () => {
    setBreadcrumbs([{title:'Inicio', to:'/home'}, {title:'Inversiones realizadas'}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){
      setIsLoadingData(true);
      get_invested_loans(showStatus, currentPage)
    }
    
    return () => { setDoRefresh(false); isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRefresh, currentPage]);

  const get_invested_loans = (showStatus, page) => {
    ApiService.invested_loans(showStatus, page)
      .then(
        (data) => {
          // console.log(data);
          setInvestedLoansCount(data.count);
          setInvestedLoansList(data.results);
          setIsLoadingData(false);

        }, error => {
          // console.log(error);
          setInvestedLoansCount(0);
          setInvestedLoansList(null);
          setIsLoadingData(false);
          NotificationManager.error(
            'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
        });
  }

  const showLoanModal = (id) => {
    setLoanViewId(id);
    setOpenLoanModal(true);
  }

  const takenColumns = useMemo(
    () => [
      {
        Header: '#',
        accesor: 'loan.id',
        headerClassName: 'table-basic-w-id',
        cellClassName: 'align-middle',
        Cell: props => props.row.original.loan.id
      },
      {
        Header: 'Motivo',
        accesor: 'reason',
        headerClassName: 'table-basic-w-field-1',
        cellClassName: 'align-middle',
        Cell: props => <LoanRequestReason reason={props.row.original.reason} />
      },
      // {
      //   Header: 'Campo1',
      //   accesor: 'loan.campo1',
      //   cellClassName: 'align-middle',
      //   Cell: props => props.row.original.loan.campo1
      // },
      // {
      //   Header: 'Campo2',
      //   accesor: 'loan.campo2',
      //   cellClassName: 'align-middle',
      //   Cell: props => props.row.original.loan.campo2
      // },
      // {
      //   Header: 'Campo3',
      //   accesor: 'loan.campo3',
      //   cellClassName: 'align-middle',
      //   Cell: props => props.row.original.loan.campo3
      // },
      {
        Header: 'Monto',
        accesor: 'amount',
        cellClassName: 'align-middle',
        Cell: props => <LoanAmount 
                          value={props.row.original.amount} />
      },
      {
        Header: 'Creado',
        accesor: 'created',
        headerClassName: 'table-basic-w-created',
        cellClassName: 'align-middle',
        Cell: props => <LoanDate value={props.row.original.created} />
      },
      {
        Header: ' ',
        accesor: row => '',
        headerClassName: 'table-basic-w-actions',
        cellClassName: 'align-middle',
        Cell: props => 
          <React.Fragment>
            <div className="d-flex flex-row align-items-center">
              <LoanStatus status={props.row.original.loan.status} />
              <button 
                type="button" 
                className="btn btn-sm p-1" 
                onClick={(e) => showLoanModal(props.row.original.loan.id)}
                ><img alt="+info" title="Mas Informacion" src="/images/info.png" /></button>
            </div>
          </React.Fragment>
      }
    ],
    []
  );

  const memoTakenLoans = useMemo(
    () => (investedLoansList), [investedLoansList]);
  
  return (
    <React.Fragment>
      
      <div className="container-fluid">
        
        <h5 className="pt-2">Inversiones realizadas</h5>

        <TableBasic 
          className="table table-hover" 
          isLoading={isLoadingData} 
          columns={takenColumns} data={memoTakenLoans} 
          resultsCount={investedLoansCount} 
          currentPage={currentPage} 
          setCurrentPage={setCurrentPage} 
          />

      </div>

      <ReactModal
        isOpen={openLoanModal}
        onRequestClose={() => setOpenLoanModal(false)}
        style={modalStyles} >

          <div className="float-end">
            <button 
              type="button" 
              className="btn-close btn-sm btn-danger bg-bantex text-white p-1"
              onClick={() => setOpenLoanModal(false)}></button>
          </div>

          <LoanModal 
            loanId={loanViewId}
            setDoRefreshMain={setDoRefresh} />

      </ReactModal>

    </React.Fragment>
    )
}

export default InvestedLoans;