function LoanStatus(props) {
  const type = props.type || 'icon';
  const status = props.status || 0;
  const className = props.className || '';
  const statusStr = [
    '',
    { label:'EN REVISION - BANTEX',    image: 'en_revision.png',  icon: 'en_revision_icono.png' },  //1
    { label:'EN REVISION - CLIENTE',   image: 'en_revision.png',  icon: 'en_revision_icono.png' },  //2
    { label:'APROBADO - CLIENTE',      image: 'aprobado.png',     icon: 'aprobado_icono.png' },     //3
    { label:'RECHAZADO - BANTEX',      image: 'rechazado.png',    icon: 'rechazado_icono.png' },    //4
    { label:'RECHAZADO - CLIENTE',     image: 'rechazado.png',    icon: 'rechazado_icono.png' },    //5
    { label:'INVERSION COMPLETA',      image: 'completa.png',     icon: 'completa_icono.png' },     //6
    { label:'DESEMBOLSADO',            image: 'desembolsado.png', icon: 'desembolsado_icono.png' }//, //7
    // { label:'FINALIZADO',              image: 'finalizado.png',   icon: 'en_revision_icono.png' }    //8
  ];

  if( status > 0 && status < statusStr.length ){
    let image = null;

    if( type === 'icon' )
      image = (
        <img  width="25" height="25"
          alt={statusStr[status].label} 
          title={statusStr[status].label} 
          className={className}
          src={'/images/' + statusStr[status].icon} />);
    else 
      image = (
        <img 
          alt={statusStr[status].label} 
          title={statusStr[status].label} 
          className={className}
          src={'/images/' + statusStr[status].image} />);

    return image;
  }else
    return null;
}

export default LoanStatus;