function LoanStatusSelect(props) {
  const onChange = props.onChange;

  return (
    <select 
      className="form-select" 
      name="loan_status"
      onChange={(e) => onChange(e.target.value)}
      >
      <option value="1">En Revision (Bantex)</option>
      <option value="2">En Revision (Cliente)</option>
      <option value="3">Aprobado</option>
      <option value="4">Rechazado (Bantex)</option>
      <option value="5">Rechazado (Cliente)</option>
      <option value="6">Inversion Completa</option>
      <option value="7">Desembolsado</option>
      <option value="8">Finalizado</option>
    </select>
  )

}

export default LoanStatusSelect;