import axios from "axios";

class ApiAdminService {

  static API_BASE_URL = "http://srvarg001.tuscombis.com:8080/api/v1/admin";
  // static API_BASE_URL = "http://localhost:8000/api/v1/admin";

  static API_CONFIG = {
    "API_LOAN":           this.API_BASE_URL + "/loan/",
    "API_LOAN_PAYOUT":    this.API_BASE_URL + "/loan-payout/",
    "API_CLIENT_PAYOUT":  this.API_BASE_URL + "/client-payout/",
    "API_LOAN_DETAIL":    this.API_BASE_URL + "/loan-detail/",
    "API_LOAN_PLAN":      this.API_BASE_URL + "/loan-plan/",
    "API_LOAN_INVESTOR":  this.API_BASE_URL + "/loan-investor/",
    "API_PROFILE":        this.API_BASE_URL + "/profile/",
    "API_CLIENT":         this.API_BASE_URL + "/client-profile/",
    "API_INVESTOR":       this.API_BASE_URL + "/investor-profile/",
  }

  loans(status=-1, page=1) {
    let filter = '?page=' + page;
    
    if( status > 0 )
      filter += '&status=' + status;

    return axios.get(ApiAdminService.API_CONFIG.API_LOAN + filter)
      .then( response => response.data );
  }

  loan_info(id) {
    return axios.get(ApiAdminService.API_CONFIG.API_LOAN + id)
      .then( response => response.data );
  }

  loan_payout_status(id) {
    return axios.get(ApiAdminService.API_CONFIG.API_CLIENT_PAYOUT + '?loan=' + id)
      .then( response => response.data );
  }

  loan_investor(id) {
    return axios.get(ApiAdminService.API_CONFIG.API_LOAN_INVESTOR + '?loan=' + id)
      .then( response => response.data );
  }

  loan_payout(id) {
    return axios.post(ApiAdminService.API_CONFIG.API_LOAN_PAYOUT + id + '/payout/')
      .then( response => response.data );
  }

  profile_info(id) {
    return axios.get(ApiAdminService.API_CONFIG.API_PROFILE + id)
      .then( response => response.data );
  }

  client_info(id) {
    return axios.get(ApiAdminService.API_CONFIG.API_CLIENT + id)
      .then( response => response.data );
  }

  investor_info(id) {
    return axios.get(ApiAdminService.API_CONFIG.API_INVESTOR + id)
      .then( response => response.data );
  }

  // admin
  loan_detail_info(id) {
    return axios.get(ApiAdminService.API_CONFIG.API_LOAN_DETAIL + id)
      .then( response => response.data );
  }

  loan_plan_info(score=0) {
    return axios.get(ApiAdminService.API_CONFIG.API_LOAN_PLAN + '?score=' + score)
      .then( response => response.data );    
  }

  create_plan_detail(data) {
    return axios.post(ApiAdminService.API_CONFIG.API_LOAN_DETAIL, data)
      .then( response => response );    
  }

}

export default new ApiAdminService();
