import React, {useState, useEffect} from 'react';
import { NotificationManager } from 'react-notifications';

import LoanInfo from './LoanInfo';
import LoanDetail from './LoanDetail';
import LoanAction from './LoanAction';
import LoanActionPayout from './LoanActionPayout';
import LoanClientAction from './LoanClientAction';
import LoanClientPayout from './LoanClientPayout';
import LoanStatusPayout from './LoanStatusPayout';
import LoanInvestorAction from './LoanInvestorAction';
import LoanInvestor from './LoanInvestor';

import ApiSelector from '../services/ApiSelector';
import AuthService from '../services/AuthService';

import StatusEnum from '../page/StatusEnum';

function LoanModal(props) {
  
  const loanId = props.loanId;
  const setDoRefreshMain = props.setDoRefreshMain; // to refresh main page
  // const showDetail = AuthService.getCurrentUser().type === 0 || AuthService.getCurrentUser().type === 1; // SOLO ADMIN O CLIENTE
  const showAdminAction = AuthService.getCurrentUser().type === 0; // SOLO ADMIN
  const showClientAction = AuthService.getCurrentUser().type === 1; // SOLO CLIENTE
  const showInvestorAction = AuthService.getCurrentUser().type === 2; // SOLO INVERSOR

  const [isLoadingData, setIsLoadingData] = useState(0);
  const [loanDetailData, setLoanDetailData] = useState(null);
  const [loanData, setLoanData] = useState(null);

  const [doRefresh, setDoRefresh] = useState(false);

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){
      if( doRefresh ){
        setDoRefreshMain(true);
        load_modal_data();
      }
    }
    
    return () => { setDoRefresh(false); isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRefresh]);

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){
      load_modal_data();
    }
    
    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanId]);

  const load_modal_data = () => {
      if( loanId > 0 ){
        setIsLoadingData(2);
        get_loan_info(loanId);
        get_loan_detail_info(loanId);

      }else{
        setLoanData(null);
        setLoanDetailData(null);
      }
  }

  const get_loan_info = (id) => {
    ApiSelector.loan_info(id)
      .then(
        (data) => {
          setLoanData(data);
          setIsLoadingData(prevState => (prevState - 1));

        }, error => {
          setIsLoadingData(prevState => (prevState - 1));
          setLoanData(null);
          NotificationManager.error(
            'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
        });
  }

  const get_loan_detail_info = (id) => {
    ApiSelector.loan_detail_info(id)
      .then(
        (data) => {
          setLoanDetailData(data);
          setIsLoadingData(prevState => (prevState - 1));

        }, error => {
          setIsLoadingData(prevState => (prevState - 1));
          setLoanDetailData(null);

          if( error.response.status !== 404 ){
            NotificationManager.error(
              'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
          }
        });
  }


  return (
    <React.Fragment>

      {isLoadingData > 0 && (
        <div 
          className="spinner-border float-end spinner-border-sm mt-3 me-2 text-bantex" 
          role="status" aria-hidden="true"></div>
      )}

      {!isLoadingData && loanData && (
        <LoanInfo 
          // loanId={loanId}
          loanData={loanData}
          // loanDetailData={loanDetailData} 
          />
      )}

      {!isLoadingData 
        && showClientAction 
        && loanData && loanData.status === StatusEnum.PENDING_BANTEX && (
          <div className="row p-1 my-2">
            <p 
              className="alert-primary border border-primary rounded p-2"
              style={{ fontSize: '14px' }}
              >Estamos revisando tu solicitud. Recibiras un email una vez finalizado el proceso.</p>
          </div>
      )}

      {!isLoadingData && loanDetailData && (
        <LoanDetail 
          // loanId={loanId}
          // loanData={loanData}
          loanDetailData={loanDetailData} 
          />
      )}

      {!isLoadingData && showAdminAction && !loanDetailData && (
        <LoanAction
          loanId={loanId}
          loanData={loanData}
          // loanDetailData={loanDetailData} 
          setRefreshModal={setDoRefresh} />
      )}

      {!isLoadingData && showAdminAction 
        && loanDetailData && loanData 
        && loanData.status === StatusEnum.INVEST_COMPLETED && (
        <LoanActionPayout
          loanId={loanId}
          loanData={loanData}
          loanDetailData={loanDetailData} 
          setRefreshModal={setDoRefresh} />
      )}

      {!isLoadingData && (showAdminAction || showClientAction)
        && loanDetailData && loanData 
        && [
            StatusEnum.APPROVED_CLIENT, 
            StatusEnum.INVEST_COMPLETED, 
            StatusEnum.PAYOUT
            ].includes(loanData.status) && (
        <LoanInvestor
          loanId={loanId}
          loanData={loanData}
          loanDetailData={loanDetailData} 
          setRefreshModal={setDoRefresh} />
      )}

      {!isLoadingData && showAdminAction 
        && loanDetailData && loanData 
        && loanData.status === StatusEnum.PAYOUT && (
        <LoanStatusPayout
          loanId={loanId}
          loanData={loanData}
          loanDetailData={loanDetailData} 
          setRefreshModal={setDoRefresh} />
      )}

      {!isLoadingData && showClientAction 
        && loanDetailData && loanData 
        && loanData.status === StatusEnum.PENDING_CLIENT && (
        <LoanClientAction
          loanId={loanId}
          setRefreshModal={setDoRefresh}
          />
      )}

      {!isLoadingData && showClientAction 
        && loanDetailData && loanData 
        && loanData.status === StatusEnum.PAYOUT && (
        <LoanClientPayout
          loanId={loanId}
          setRefreshModal={setDoRefresh}
          />
      )}

      {!isLoadingData && showInvestorAction 
        && loanDetailData && loanData 
        && [
          StatusEnum.APPROVED_CLIENT, 
          StatusEnum.INVEST_COMPLETED, 
          StatusEnum.PAYOUT].includes(loanData.status) && (
        <LoanInvestorAction
          loanId={loanId}
          loanData={loanData}
          // loanDetailData={loanDetailData} 
          setRefreshModal={setDoRefresh}
          />
      )}

    </React.Fragment>
  );
}

export default LoanModal;