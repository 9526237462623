function LoanRequestReasonSelect(props) {

  const value = props.value;
  const required = props.required;
  const handleChange = props.handleChange;
  const loanReasons = {
    1:  "Refinanciamiento de tarjeta de credito",
    2:  "Consolidacion de deudas",
    3:  "Mejoras en el hogar",
    4:  "Adiciones de infraestructuras en el hogar",
    5:  "Financiamiento de vivienda (detallar)",
    6:  "Financiamiento del automovil (detallar)",
    7:  "Financiamiento para negocios (detallar)",
    8:  "Vacaciones",
    9:  "Mudanza y reubicacion",
    10: "Gastos medicos",
    11: "Otros (detallar)",
  };

  return (
    <select 
      required={required}
      className="form-select" 
      id="reason" 
      value={value} 
      onChange={handleChange} 
      placeholder="Motivo de la solicitud"
      >
      {Object.entries(loanReasons).map( (value) => (
        <option key={"reason_" + value[0]} value={value[0]}>{value[1]}</option>
      ))}
    </select>
  )

}

export default LoanRequestReasonSelect;