import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router';
import {
	Link
} from "react-router-dom";

import { NotificationManager } from 'react-notifications';

import PidInput from '../components/PidInput';
import BirthdaySelect from '../components/BirthdaySelect';
import CountrySelect from '../components/CountrySelect';

import AuthService from '../services/AuthService';
import ApiService from '../services/ApiService';

function RegisterForm(props) {

	const registerFormInitialState = {
		// access data
		email: '',
		username: '',
		password: '',
		passwordR: '',
		// profile type
		type: 1,
		// personal data
		gender: 1,
		name: '',
		lastname_first: '',
		lastname_second: '',
		pid_type: 1,
		pid: '',
		birthdate: '',
		// birth_month: 1,
		// birth_day: 1,
		// birth_year: parseInt(new Date().getFullYear()) - 18, // TODO: Ojo con los selects de fecha, hay que convertirlo en un componente que maneje la fecha completa
		country: 'PA',
		marital_status: 1,
		address: '',
		tel_home: '',
		tel_mobile: '',
		tel_manage: '',
		parent_name: '',
		parent_pid: ''
	};
	const [registerForm, setRegisterForm] = useState(registerFormInitialState);
  	const [registrationStep, setRegistrationStep] = useState(1);
	const [isSubmiting, setIsSubmiting] = useState(false);

	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);
  	
  	const setShowHeader = props.setShowHeader;
  	const setShowFooter = props.setShowFooter;
  	const setBreadcrumbs = props.setBreadcrumbs;

	useEffect(() => {
		setShowHeader(false);
		setShowFooter(false);
	    setBreadcrumbs([{title:'Crear cuenta'}]);

		if( AuthService.getCurrentUser() )
			props.history.push('/home');

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();

		const form = e.target;
		let error = false;

        if (!form.checkValidity()) {
            error = true;
        }

        // chequear password === passwordR

        // los datos vienen del state
        // form validation

		form.classList.add('was-validated');
		
		if( !error ) {
			setIsSubmiting(true)

			let final_data = {
				user: {
					email: registerForm.email,
					username: registerForm.username,
					// TODO: Chequear passwordR === password
					password: registerForm.password
				},
				profile: {
					type: registerForm.type,
					gender: registerForm.gender,
					name: registerForm.name,
					lastname_first: registerForm.lastname_first,
					lastname_second: registerForm.lastname_second,
					pid_type: registerForm.pid_type,
					pid: registerForm.pid,
					birthdate: registerForm.birthdate,
					// birthdate: new Date(registerForm.birth_year, 
					// 	registerForm.birth_month, registerForm.birth_day),
					country: registerForm.country,
					marital_status: registerForm.marital_status,
					address: registerForm.address,
					tel_home: registerForm.tel_home,
					tel_mobile: registerForm.tel_mobile,
					tel_manage: registerForm.tel_manage,
					parent_name: registerForm.parent_name,
					parent_pid: registerForm.parent_pid
				}
			};

			// TODO: Chequear passwordR
			// delete final_data.passwordR;

			// final_data.birthdate = new Date(
			// 	registerForm.birth_year, registerForm.birth_month, registerForm.birth_day);
			// delete final_data.birth_year;
			// delete final_data.birth_month;
			// delete final_data.birth_day;

		    // if( registerForm.profile === 1 ){ // client profile
		    // 	final_data = {...final_data, client_data: registerForm.client_data};
		    // }else if( registerForm.profile === 2 ){ // investor profile
		    // 	final_data = {...final_data, investor_data: registerForm.investor_data};
		    // }else
		    //   return null;

			ApiService.register(final_data)
				.then(
				  () => {
				    setIsSubmiting(false);
				    NotificationManager.success('Registro realizado con exito!');
				    setRegistrationStep(2);
				  },
				  error => {
				    setIsSubmiting(false);
				    // console.log(error.response);
				    NotificationManager.error('Por favor, verifique los datos ingresados.', 'Error');
				  }
				);
		}
	};

	// const monthDays = [
	// 	-1,31,28,31,30,31,30,31,31,30,31,30,31
	// ]

	// const CurrentBirthMonthDaysOptions = (props) => {
	// 	const selectedMonth = props.selectedMonth;

	// 	return (
	// 		<React.Fragment>
	// 			{Array.from(Array(monthDays[selectedMonth])).map( (v, i) => (
	// 				<option value={i + 1} key={"day_" + (i + 1)}>{i + 1}</option>
	// 			))}
	// 		</React.Fragment>
	// 	);
	// };

	// const AvailableBirthYears = (props) => {
	// 	let current_year = new Date().getFullYear();
	// 	let max_year = current_year - 18;
	// 	let min_year = max_year - 100;
	// 	let year_range = Array(max_year - min_year + 1).fill().map((_, idx) => min_year + idx);

	// 	return (
	// 		<React.Fragment>
	// 			{year_range.map( (v, i) => (
	// 				<option value={v} key={"year_" + v}>{v}</option>
	// 			))}
	// 		</React.Fragment>
	// 	);
	// };

	const handleChange = (e) => {
		const {id , value} = e.target;
		setRegisterForm(prevState => ({
			...prevState,
			[id] : value
		}));
	};

	// const handleKeyUp = (e) => {

	// 	var selection = window.getSelection().toString();
	//     if ( selection !== '' ) {
	//         return;
	//     }

	//     if ( [38,40,37,39].includes(e.keyCode) ) {
	//         return;
	//     }		

	// 	let input = e.target;
	// 	let val = input.value;

	// 	if( parseInt(registerForm.pid_type) === 1 )
	// 		input.value = formatPIDNumber(val);

	// 	else if( parseInt(registerForm.pid_type) === 2 )
	// 		input.value = formatPassportNumber(val);

	// 	else if( parseInt(registerForm.pid_type) === 3 )
	// 		input.value = formatRUCNumber(val);

	// 	else
	// 		NotificationManager.error('Error validando datos.', 'Error');
	// };

	// const formatPIDNumber = (val) => {
	//     val = val.replace(/[^0-9]+/g, "");
	// 	val = val.replace(/(\d{3})(?=(\d{3})+)/g, "$1-");
	// 	return val;
	// };

	// const formatPassportNumber = (val) => {
	//     val = val.replace(/[^0-9a-zA-Z]+/g, "");
	// 	return val;
	// };

	// const formatRUCNumber = (val) => {
	//     val = val.replace(/[^0-9]+/g, "");
	// 	return val;
	// };

	const submitScreen = (
		<React.Fragment>
			<h2>Cuenta creada con exito!</h2>
			<p>Recibiras un email para activar tu cuenta.</p>

			<p><Link to="/login">Iniciar Sesion</Link></p>
		</React.Fragment>
	);

	// CLIENTE
	const registerFormClientHTML = (
		<React.Fragment>

    		<div className="row mb-3">
				<div className="col-md">
					<p>Este es un texto explicando el tipo de cuenta Cliente</p>
				</div>
			</div>

    		<div className="row mb-3">
				<div className="col bg-bantex text-white">
					<span>Situacion financiera</span>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-4">
					<div className="form-floating mb-2">
						<select className="form-select" id="property_type" placeholder="Tipo de vivienda">
							<option>Alquilada</option>
							<option>Vive con los padres</option>
							<option>Otra</option>
						</select>
						<label htmlFor="property_type">Tipo de vivienda</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="property_value" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Valor de la propiedad"
							required 
							/>
						<label htmlFor="property_value">Valor de la propiedad</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md-auto">
					<span>Hipoteca</span>
				</div>
				<div className="col-md-auto">
					<div className="btn-group" role="group" aria-label="Hipoteca">
						<input type="radio" className="btn-check btn-check-bantex" name="property_mortgage" id="property_mortgageyes" value="1" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="property_mortgageyes">Si</label>

						<input type="radio" className="btn-check btn-check-bantex" name="property_mortgage" id="property_mortgageno" value="2" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="property_mortgageno">No</label>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="property_mortgage_bank" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Banco"
							required 
							disabled
							/>
						<label htmlFor="property_mortgage_bank">Banco</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="property_mortgage_balance" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Saldo"
							required 
							disabled
							/>
						<label htmlFor="property_mortgage_balance">Saldo</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="property_mortgage_monthly" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Pago mensual"
							required 
							disabled
							/>
						<label htmlFor="property_mortgage_monthly">Pago mensual</label>
					</div>
				</div>
			</div>

    		<div className="row mb-3">
				<div className="col-md">
					<h5>Vehiculo</h5>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md-auto">
					<span>Vehiculo</span>
				</div>
				<div className="col-md">
					<div className="btn-group" role="group" aria-label="Vehiculo">
						<input type="radio" className="btn-check btn-check-bantex" name="vehicle" id="vehicleyes" value="1" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="vehicleyes">Si</label>

						<input type="radio" className="btn-check btn-check-bantex" name="vehicle" id="vehicleno" value="2" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="vehicleno">No</label>
					</div>
				</div>
				<div className="col-md-auto">
					<span>Financiado</span>
				</div>
				<div className="col-md">
					<div className="btn-group" role="group" aria-label="Vehiculo financiado">
						<input type="radio" className="btn-check btn-check-bantex" name="vehicle_mortgage" id="vehicle_mortgageyes" value="1" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="vehicle_mortgageyes">Si</label>

						<input type="radio" className="btn-check btn-check-bantex" name="vehicle_mortgage" id="vehicle_mortgageno" value="2" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="vehicle_mortgageno">No</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="vehicle_mortgage_bank" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Banco"
							required 
							disabled
							/>
						<label htmlFor="vehicle_mortgage_bank">Banco</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="vehicle_mortgage_monthly" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Cuota"
							required 
							disabled
							/>
						<label htmlFor="vehicle_mortgage_monthly">Cuota</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="vehicle_year" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="A&ntilde;o"
							required 
							disabled
							/>
						<label htmlFor="vehicle_year">A&ntilde;o</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="vehicle_plate" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Placa"
							required 
							disabled
							/>
						<label htmlFor="vehicle_plate">Placa</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="vehicle_mortgage_balance" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Saldo financiamiento"
							required 
							disabled
							/>
						<label htmlFor="vehicle_mortgage_balance">Saldo financiamiento</label>
					</div>
				</div>
			</div>

    		<div className="row mb-3">
				<div className="col-md">
					<h5>Tarjeta de credito</h5>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="cc1_bank" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Emisor"
							required 
							/>
						<label htmlFor="cc1_bank">Emisor</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="cc1_limit" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Limite"
							required 
							/>
						<label htmlFor="cc1_limit">Limite</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="cc1_balance" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Saldo"
							required 
							/>
						<label htmlFor="cc1_balance">Saldo</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="cc1_monthly" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Cuota"
							required 
							/>
						<label htmlFor="cc1_monthly">Cuota</label>
					</div>
				</div>
			</div>

    		<div className="row mb-3">
				<div className="col-md">
					<h5>Tarjeta de credito</h5>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="cc2_bank" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Emisor"
							required 
							/>
						<label htmlFor="cc2_bank">Emisor</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="cc2_limit" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Limite"
							required 
							/>
						<label htmlFor="cc2_limit">Limite</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="cc2_balance" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Saldo"
							required 
							/>
						<label htmlFor="cc2_balance">Saldo</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="cc2_monthly" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Cuota"
							required 
							/>
						<label htmlFor="cc2_monthly">Cuota</label>
					</div>
				</div>
			</div>

    		<div className="row mb-3">
				<div className="col-md">
					<h5>Credito personal</h5>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="loan_bank" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Banco"
							required 
							/>
						<label htmlFor="loan_bank">Banco</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="loan_balance" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Saldo"
							required 
							/>
						<label htmlFor="loan_balance">Saldo</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="loan_monthly" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Cuota mensual"
							required 
							/>
						<label htmlFor="loan_monthly">Cuota mensual</label>
					</div>
				</div>
			</div>

    		<div className="row mb-3">
				<div className="col-md">
					<h5>Otros</h5>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="other_investments" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Otras inversiones"
							required 
							/>
						<label htmlFor="other_investments">Otras inversiones</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md">
					<span>&iquest;Ha tenido problemas financieros en los ultimos 3 a&ntilde;os?</span>
				</div>
				<div className="col-md-auto">
					<div className="btn-group" role="group" aria-label="Problemas financieros">
						<input type="radio" className="btn-check btn-check-bantex" name="other_financial_problem" id="other_financial_problemyes" value="1" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="other_financial_problemyes">Si</label>

						<input type="radio" className="btn-check btn-check-bantex" name="other_financial_problem" id="other_financial_problemno" value="2" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="other_financial_problemno">No</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md">
					<span>&iquest;Algun juicio/fallo judicial en su contra?</span>
				</div>
				<div className="col-md-auto">
					<div className="btn-group" role="group" aria-label="Juicio o fallo en su contra">
						<input type="radio" className="btn-check btn-check-bantex" name="other_law_problem" id="other_law_problemyes" value="1" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="other_law_problemyes">Si</label>

						<input type="radio" className="btn-check btn-check-bantex" name="other_law_problem" id="other_law_problemno" value="2" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="other_law_problemno">No</label>
					</div>
				</div>
			</div>

		</React.Fragment>
	);

	// INVERSOR
	const registerFormInvestorHTML = (

		<React.Fragment>

    		<div className="row mb-3">
				<div className="col-md">
					<p>Este es un texto explicando el tipo de cuenta Inversor</p>
				</div>
			</div>

    		<div className="row mb-3">
				<div className="col bg-bantex text-white">
					<span>Perfil del cliente</span>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md">
					<span>&iquest;Esta usted acusado o procesado por algun tipo de delito?</span>
				</div>
				<div className="col-md-auto">
					<div className="btn-group" role="group" aria-label="Perfil de cliente - Pregunta 1">
						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_1" id="profile_q_1yes" value="1" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_1yes">Si</label>

						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_1" id="profile_q_1no" value="2" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_1no">No</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md">
					<span>&iquest;Es usted o esta usted relacionado (familia o socios de negocios) de alguna forma con una persona publicamente expuesta?</span>
				</div>
				<div className="col-md-auto">
					<div className="btn-group" role="group" aria-label="Perfil de cliente - Pregunta 2">
						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_2" id="profile_q_2yes" value="1" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_2yes">Si</label>

						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_2" id="profile_q_2no" value="2" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_2no">No</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md">
					<span>&iquest;Eres ciudadano o residente de Panama?</span>
				</div>
				<div className="col-md-auto">
					<div className="btn-group" role="group" aria-label="Perfil de cliente - Pregunta 3">
						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_3" id="profile_q_3yes" value="1" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_3yes">Si</label>

						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_3" id="profile_q_3no" value="2" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_3no">No</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md">
					<span>&iquest;Eres ciudadano o residente de los Estados Unidos?</span>
				</div>
				<div className="col-md-auto">
					<div className="btn-group" role="group" aria-label="Perfil de cliente - Pregunta 4">
						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_4" id="profile_q_4yes" value="1" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_4yes">Si</label>

						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_4" id="profile_q_4no" value="2" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_4no">No</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md">
					<span>Cuentanos sobre tu fuente de ingreso.</span>
				</div>
				<div className="col-md-auto">
					<div className="btn-group" role="group" aria-label="Perfil de cliente - Pregunta 5">
						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_5" id="profile_q_51" value="1" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_51">Asalariado</label>

						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_5" id="profile_q_52" value="2" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_52">Negocio</label>

						<input type="radio" className="btn-check btn-check-bantex" name="profile_q_5" id="profile_q_53" value="3" autoComplete="off" />
						<label className="btn btn-outline-primary btn-outline-bantex" htmlFor="profile_q_53">Retirado</label>
					</div>
				</div>
			</div>

		</React.Fragment>
	);

	// USER
	const registerFormAccessDataHTML = (
		<React.Fragment>
			<div className="row mb-3">
				<div className="col bg-bantex text-white">
					<span>Datos de acceso</span>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="email" 
							className="form-control" 
							id="email" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Correo Electronico"
							required 
							/>
						<label htmlFor="email">Correo Electronico</label>
						<div className="invalid-feedback">Por favor ingrese su direccion de correo electronico.</div>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="username" 
							value={registerForm.username}
							onChange={handleChange}
							aria-describedby="usernameHelp" 
							placeholder="Nombre de usuario"
							required 
							/>
						<label htmlFor="username">Nombre de usuario</label>
						<div className="invalid-feedback">Por favor ingrese un nombre de usuario valido.</div>
			            {/*<div id="usernameHelp" className="form-text">
			            	Minimo 8 caracteres. Solo se admiten letras (a-zA-Z) y numeros (0-9)
			            </div>*/}
					</div>
				</div>
			</div>

			{/*
			<div className="row mb-3">
			</div>
			*/}
			
			<div className="row mb-3">
				<div className="col-md-6">
					<div className="form-floating mb-2">
						<input 
							type="password" 
							className="form-control" 
							id="password" 
							value={registerForm.password}
							onChange={handleChange}
							aria-describedby="passwdHelp" 
							placeholder="Contrase&ntilde;a"
							required 
							/>
						<label htmlFor="password">Contrase&ntilde;a</label>
						<div className="invalid-feedback">Por favor ingrese una contrase&ntilde;a valida.</div>
			            <div id="passwdHelp" className="form-text">
			            	Debe incluir uno o mas numeros (0-9)
			            </div>
					</div>
				</div>

				<div className="col-md-6">
					<div className="form-floating mb-2">
						<input 
							type="password" 
							className="form-control" 
							id="passwordR" 
							value={registerForm.passwordR}
							onChange={handleChange}
							aria-describedby="passwdHelpR" 
							placeholder="Repetir Contrase&ntilde;a"
							required 
							/>
						<label htmlFor="passwordR">Repetir Contrase&ntilde;a</label>
						<div className="invalid-feedback">La contrase&ntilde;a ingresada no coincide.</div>
			            <div id="passwdHelpR" className="form-text">
			            	Por favor, ingrese nuevamente su contrase&ntilde;a
			            </div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);

	// PROFILE
	const registerFormPersonalDataHTML = (
		<React.Fragment>
			<div className="row mb-3">
				<div className="col bg-bantex text-white">
					<span>Datos personales</span>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-3">
					<div className="form-floating mb-2">
						<select 
							className="form-select" 
							id="gender" 
							value={registerForm.gender}
							onChange={handleChange}
							placeholder="Sexo">
							<option value="1">Hombre</option>
							<option value="2">Mujer</option>
						</select>
						<label htmlFor="gender">Sexo</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="name" 
							value={registerForm.name}
							onChange={handleChange}
							placeholder="Nombre/s"
							required 
							/>
						<label htmlFor="name">Nombre/s</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-6">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="lastname_first" 
							value={registerForm.lastname_first}
							onChange={handleChange}
							placeholder="Primer Apellido"
							required 
							/>
						<label htmlFor="lastname_first">Primer Apellido</label>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="lastname_second" 
							value={registerForm.lastname_second}
							onChange={handleChange}
							placeholder="Segundo Apellido"
							required 
							/>
						<label htmlFor="lastname_second">Segundo Apellido</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md-4">
					<div className="form-floating mb-2">
						<select 
							className="form-select" 
							id="pid_type" 
							placeholder="Tipo de Identificacion"
							onChange={handleChange}
							value={registerForm.pid_type} 
							>
							<option value="1">Cedula</option>
							<option value="2">Pasaporte</option>
							<option value="3">Residencia</option>
						</select>
						<label htmlFor="pid_type">Tipo de Identificacion</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
			            <PidInput 
			              id="pid" 
			              value={registerForm.pid}
			              onChange={handleChange}
			              pidType={registerForm.pid_type}
			              required={true}
			              />
						{/*
						<input 
							type="text" 
							className="form-control" 
							id="pid" 
							value={registerForm.pid}
							onKeyUp={handleKeyUp}
							onChange={handleChange}
							placeholder="Nro. de Identificacion"
							required 
							/>
						*/}
						<label htmlFor="pid">Nro. de Identificacion</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<label htmlFor="borndate" className="col-md-6 form-col-label">Fecha de nacimiento</label>
				<div className="col-md">
					<BirthdaySelect
						id="birthdate"
						onChange={handleChange}
						value={registerForm.birthdate} />
				</div>
			</div>

			{/*
			<div className="row mb-3 align-items-center">
				<label htmlFor="borndate" className="col-md-6 form-col-label">Fecha de nacimiento</label>
				<div className="col-md">
					<div className="input-group">
						<select 
							className="form-select"
							id="birth_month" 
							value={registerForm.birth_month}
							onChange={handleChange}
							>
							<option value="1">Enero</option>
							<option value="2">Febrero</option>
							<option value="3">Marzo</option>
							<option value="4">Abril</option>
							<option value="5">Mayo</option>
							<option value="6">Junio</option>
							<option value="7">Julio</option>
							<option value="8">Agosto</option>
							<option value="9">Septiembre</option>
							<option value="10">Octubre</option>
							<option value="11">Noviembre</option>
							<option value="12">Diciembre</option>
						</select>
						<select 
							id="birth_day" 
							value={registerForm.birth_day}
							onChange={handleChange}
							className="form-select"
							>
							<CurrentBirthMonthDaysOptions
								selectedMonth={registerForm.birth_month}
								/>
						</select>
						<select 
							id="birth_year" 
							value={registerForm.birth_year}
							onChange={handleChange}
							className="form-select"
							>
							<AvailableBirthYears />
						</select>
					</div>
				</div>
			</div>
			*/}
			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
					
						<CountrySelect 
							value={registerForm.country}
							handleChange={handleChange}
							/>

						<label htmlFor="country">Nacionalidad</label>
					</div>
				</div>

				<div className="col-md">
					<div className="form-floating mb-2">
						<select 
							className="form-select" 
							id="marital_status" 
							value={registerForm.marital_status}
							onChange={handleChange}
							placeholder="Estado Civil"
							>
							<option value="1">Soltero</option>
							<option value="2">Casado</option>
							<option value="3">Divorciado</option>
							<option value="4">Viudo</option>
						</select>
						<label htmlFor="marital_status">Estado Civil</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="address" 
							value={registerForm.address}
							onChange={handleChange}
							placeholder="Direccion de residencia"
							required />
						<label htmlFor="address">Direccion de residencia</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="tel_home" 
							value={registerForm.tel_home}
							onChange={handleChange}
							placeholder="Telefono de residencia"
							required />
						<label htmlFor="tel_home">Telefono de residencia</label>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="tel_mobile" 
							value={registerForm.tel_mobile}
							onChange={handleChange}
							placeholder="Telefono celular"
							required />
						<label htmlFor="tel_mobile">Telefono celular</label>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="tel_manage" 
							value={registerForm.tel_manage}
							onChange={handleChange}
							placeholder="Telefono para gestion"
							required />
						<label htmlFor="tel_manage">Telefono para gestion</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-7">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="parent_name" 
							value={registerForm.parent_name}
							onChange={handleChange}
							placeholder="Nombre completo del conyuge"
							required />
						<label htmlFor="parent_name">Nombre completo del conyuge</label>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="parent_pid" 
							value={registerForm.parent_pid}
							onChange={handleChange}
							placeholder="Nro. de Identificacion"
							required />
						<label htmlFor="parent_pid">Nro. de Identificacion</label>
					</div>
				</div>
			</div>
		</React.Fragment>
	);

	const registerFormParentDataHTML = (
		<React.Fragment>
			
			<div className="row mb-3">
				<div className="col bg-bantex text-white">
					<span>Pariente cercano</span>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-7">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="parent_other_name" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Nombre completo"
							required />
						<label htmlFor="parent_other_name">Nombre completo</label>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="parent_other_desc" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Parentesco"
							required />
						<label htmlFor="parent_other_desc">Parentesco</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="parent_other_address" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Direccion de residencia"
							required />
						<label htmlFor="parent_other_address">Direccion de residencia</label>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="parent_other_tel_home" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Telefono de residencia"
							required />
						<label htmlFor="parent_other_tel_home">Telefono de residencia</label>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="parent_other_tel_office" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Telefono oficina"
							required />
						<label htmlFor="parent_other_tel_office">Telefono oficina</label>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="tel_other" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Otro telefono"
							required />
						<label htmlFor="tel_other">Otro telefono</label>
					</div>
				</div>
			</div>

		</React.Fragment>
	);

	const registerFormWorkDataHTML = (
		<React.Fragment>
			<div className="row mb-3">
				<div className="col bg-bantex text-white">
					<span>Datos laborales</span>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-7">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="work_place" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Lugar de trabajo"
							required />
						<label htmlFor="work_place">Lugar de trabajo</label>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="work_position" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Puesto"
							required />
						<label htmlFor="work_position">Puesto</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<label htmlFor="borndate" className="col-md-6 form-col-label">Fecha de ingreso</label>
				<div className="col-md">
					<div className="input-group">
						<select className="form-select">
							<option value="1">Agosto</option>
						</select>
						<select className="form-select">
							<option value="1">18</option>
						</select>
						<select className="form-select">
							<option value="1">1984</option>
						</select>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="work_salary" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Salario actual"
							required />
						<label htmlFor="work_salary">Salario actual</label>
					</div>
				</div>
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="work_tel" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Telefono"
							required />
						<label htmlFor="work_tel">Telefono</label>
					</div>
				</div>
			</div>

		</React.Fragment>
	);

	const registerFormBankDataHTML = (
		<React.Fragment>

    		<div className="row mb-3">
				<div className="col bg-bantex text-white">
					<span>Datos bancarios</span>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="bank_account" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Nro. de cuenta"
							required 
							/>
						<label htmlFor="bank_account">Nro. de cuenta</label>
					</div>
				</div>
				<div className="col-md-4">
					<div className="form-floating mb-2">
						<select className="form-select" id="bank_account_type" placeholder="Tipo de cuenta bancaria">
							<option>Ahorro</option>
							<option>Corriente</option>
						</select>
						<label htmlFor="bank_account_type">Tipo de cuenta bancaria</label>
					</div>
				</div>
			</div>

			<div className="row mb-3 align-items-center">
				<div className="col-md">
					<div className="form-floating mb-2">
						<input 
							type="text" 
							className="form-control" 
							id="bank_name" 
							value={registerForm.email}
							onChange={handleChange}
							placeholder="Banco al que pertenece la cuenta"
							required 
							/>
						<label htmlFor="bank_name">Banco al que pertenece la cuenta</label>
					</div>
				</div>
			</div>

		</React.Fragment>
	);

	const registerFormHTML = (
		<React.Fragment>
			<h2>Crear cuenta</h2>

			<form onSubmit={handleSubmit} noValidate>

				{registerFormAccessDataHTML}

				{registerFormPersonalDataHTML}

				{/*registerFormParentDataHTML*/}

				{/*registerFormWorkDataHTML*/}

				{/*registerFormBankDataHTML*/}

				<div className="row mb-3">
					<div className="col bg-bantex text-white">
						<span>Tipo de cuenta</span>
					</div>
				</div>

				<div className="row mb-3">
					<ul className="nav nav-pills justify-content-center">
						<li className="nav-item">
							<a 
								className="nav-link nav-link-bantex active" 
								id="tab-client"
								aria-current="page" 
								href="#cuenta-cliente"
								onClick={(e) => setRegisterForm( prevState => ({...prevState, type: 1}) )}
								data-bs-toggle="tab" data-bs-target="#cuenta-cliente"
	                            role="tab" aria-controls="cuenta-cliente" aria-selected="true"
								>Cuenta Cliente</a>
						</li>
						<li className="nav-item">
							<a 
								className="nav-link nav-link-bantex" 
								id="tab-investor"
								href="#cuenta-inversor"
								onClick={(e) => setRegisterForm( prevState => ({...prevState, type: 2}) )}
								data-bs-toggle="tab" data-bs-target="#cuenta-inversor"
	                            role="tab" aria-controls="cuenta-inversor" aria-selected="false"
								>Cuenta Inversor</a>
						</li>
					</ul>
				</div>

				{/*
				<div className="row mb-3">
					<div className="col bg-bantex text-white">
						<span>Terminos y condiciones</span>
					</div>
				</div>
				*/}

				<div className="row mb-3 mt-4">
					<div className="col-md">
						<div className="form-check">
						  <input 
						  	className="form-check-input" 
						  	type="checkbox" 
						  	value="1" 
						  	id="accepted-terms" 
						  	required
						  	onChange={(e) => setAcceptedTerms(!acceptedTerms)} />
						  
						  <label className="form-check-label" htmlFor="accepted-terms">
						    He leido y acepto los <a href="#terminos">Terminos y condiciones</a>.
						  </label>

						  <div className="invalid-feedback">Debe aceptar los terminos y condiciones.</div>
						</div>
					</div>
				</div>

				{/*
				<div className="row mb-3">
					<div className="col bg-bantex text-white">
						<span>Politica de privacidad</span>
					</div>
				</div>
				*/}

				<div className="row mb-3">
					<div className="col-md">
						<div className="form-check">
						  <input 
						  	className="form-check-input" 
						  	type="checkbox" 
						  	value="1" 
						  	id="accepted-privacy" 
						  	required
						  	onChange={(e) => setAcceptedPrivacy(!acceptedPrivacy)} />
						  <label className="form-check-label" htmlFor="accepted-privacy">
						    He leido y acepto las <a href="#politica">Politicas de privacidad</a>.
						  </label>
						  <div className="invalid-feedback">Debe aceptar las politicas de privacidad.</div>
						</div>
					</div>
				</div>


	            <div className="container-fluid justify-content-center mb-3">
	            	<button 
	            		type="submit" 
	            		className="btn bg-bantex text-white w-100" 
		                disabled={isSubmiting}
		                >
	            		Registrarme
		                {isSubmiting && (
	                      <span 
	                      	className="spinner-border spinner-border-sm mx-2" 
	                      	role="status" aria-hidden="true"></span>
	                    )}
	            	</button>
	            </div>
	          	
	          	<p>Ya estas registrado? <Link to="/login">Iniciar Sesion</Link></p>

			</form>
		</React.Fragment>
	);

	return (
		<div className="container bg-light border rounded-3 mt-3 p-3" style={{ width: '700px' }}>
			{registrationStep === 2 ? submitScreen : registerFormHTML}
		</div>
	)
}

export default withRouter(RegisterForm);