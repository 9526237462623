
function TablePagination(props) {
  
  const setCurrentPage = props.setCurrentPage;
  const resultsCount = props.resultsCount;
  const currentPage = props.currentPage || 1;
  
  const pageSize = 10;
  const totalPages = Math.ceil(resultsCount / pageSize);
  const hasPrevPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  return totalPages > 1 && (
    <nav>
      <ul className="pagination pagination-sm justify-content-center">

        <li className={"page-item " + (!hasPrevPage ? 'disabled' : '')}>
          <a 
            className="page-link page-link-bantex" 
            // href={"?page=" + (currentPage - 1)}
            href="#prev"
            onClick={() => setCurrentPage(currentPage -1)} 
            >&lt;&lt;</a>
        </li>

        {Array.from({length: totalPages}).map( (_, v) => (
          <li 
            key={"page_" + (v + 1)} 
            className={"page-item " + ((v + 1) === currentPage ? 'active' : '')}>
            <a 
              className="page-link page-link-bantex" 
              // href={"?page=" + (v + 1)}
              href={"#page" + (v + 1)} 
              onClick={() => setCurrentPage(v + 1)} 
              >{v + 1}</a>
          </li>
        ))}

        <li className={"page-item " + (!hasNextPage ? 'disabled' : '')}>
          <a 
            className="page-link page-link-bantex" 
            // href={"?page=" + (currentPage + 1)}
            href="#next"
            onClick={() => setCurrentPage(currentPage + 1)} 
            >&gt;&gt;</a>
        </li>

      </ul>
    </nav>
  );
}

export default TablePagination;