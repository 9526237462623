import {
  Link
} from "react-router-dom";

import AuthService from '../services/AuthService'

function Breadcrumbs(props) {

  const path = props.path || null;

  return path ? (
      <div 
        className="container-fluid bg-bantex text-white fixed-top" 
        style={{ height: '25px', marginTop: '58px', zIndex: '0' }}
        >
        
        {AuthService.getCurrentUser() && (
          <span className="float-end">Hola, {AuthService.getCurrentUser().username}</span>
        )}

        {path && path.map( (i, k) => 
            (
              <span key={"br_" + k}> {k ? ' > ' : ''} 
                {i.to ? (<Link className="link-light" to={i.to}>{i.title}</Link>) : i.title}
              </span>
            )
          )}
      </div>
    ) : null;
}

export default Breadcrumbs;