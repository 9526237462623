import React, {useState, useEffect} from 'react';

import { NotificationManager } from 'react-notifications';

import StatusEnum from '../page/StatusEnum';
import ApiService from '../services/ApiService';
import LoanAmount from './LoanAmount';

function LoanInvestorAction(props) {

  const loanId = props.loanId;
  const loanData = props.loanData;
  const setRefreshModal = props.setRefreshModal;

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loanInvestorData, setLoanInvestorData] = useState(null);
  // const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [doRefresh, setDoRefresh] = useState(false);

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){
      if( doRefresh ){
        setRefreshModal(true);
        load_modal_data();
      }
    }
    
    return () => { setDoRefresh(false); isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRefresh]);

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){
      load_modal_data();
    }
    
    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanId]);

  const load_modal_data = () => {
    if( loanId > 0 ){
      setIsLoadingData(true);
      get_loan_investor_info(loanId);

    }else{
      setLoanInvestorData(null);
    }
  }

  const get_loan_investor_info = (id) => {
    ApiService.loan_investor(id)
      .then(
        (data) => {
          setLoanInvestorData(data.results[0] || null);
          setIsLoadingData(false);

        }, error => {
          setIsLoadingData(false);
          setLoanInvestorData(null);
          NotificationManager.error(
            'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
        });
  }

  return (
    <React.Fragment>
      <form id="loanInvestorActionForm">

        {isLoadingData && (
          <div 
            className="spinner-border float-end spinner-border-sm mt-3 me-2 text-bantex" 
            role="status" aria-hidden="true"></div>
        )}

        {!isLoadingData && (
          <LoanInvestorActionApprove 
            loanId={loanId}
            setRefreshModal={setDoRefresh} 
            loanData={loanData}
            loanInvestorData={loanInvestorData}
            // setIsSubmittingData={setIsSubmittingData}
            />
          )}

      </form>
    </React.Fragment>
    )
}

function LoanInvestorActionApprove(props) {

  const loanId = props.loanId;
  const setRefreshModal = props.setRefreshModal;
  const loanData = props.loanData
  const loanInvestorData = props.loanInvestorData;
  // const setIsSubmittingData = props.setIsSubmittingData

  const [disabled, setDisabled] = useState(false);
  const [investAmount, setInvestAmount] = useState(0);
  const maxInvestAmount = loanData.amount - loanData.invested_amount;
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [isRemovingInvest, setIsRemovingInvest] = useState(false);
  const [isAddingInvest, setIsAddingInvest] = useState(false);
  const [addInvestAmount, setAddInvestAmount] = useState(0);

  const submitApprove = () => {
    setIsSubmittingData(true);
    setDisabled(true);

    ApiService.loan_accept(loanId, investAmount)
      .then(
        (data) => {
          setIsSubmittingData(false);
          setRefreshModal(true);

          NotificationManager.success(
            'Se realizo correctamente la inversion.');

        }, error => {
          setDisabled(false);
          setIsSubmittingData(false);
          NotificationManager.error(
            'Ocurrio un error aprobando la propuesta. Por favor intente nuevamente.', 'Error');
        });

  }

  const removeInvest = () => {
    setIsRemovingInvest(true);
    setDisabled(true);

    ApiService.loan_remove_invest(loanId)
      .then(
        (data) => {
          setIsRemovingInvest(false);
          setRefreshModal(true);

          NotificationManager.success(
            'Se retiro correctamente la inversion.');

        }, error => {
          setDisabled(false);
          setIsRemovingInvest(false);
          NotificationManager.error(
            'Ocurrio un error. Por favor intente nuevamente.', 'Error');
        });
  }

  const addInvest = () => {
    setIsAddingInvest(true);
    setDisabled(true);

    ApiService.loan_add_invest(loanId, addInvestAmount)
      .then(
        (data) => {
          setIsAddingInvest(false);
          setRefreshModal(true);

          NotificationManager.success(
            'Se agrego correctamente la inversion.');

        }, error => {
          setDisabled(false);
          setIsAddingInvest(false);
          NotificationManager.error(
            'Ocurrio un error. Por favor intente nuevamente.', 'Error');
        });
  }

  return (
    <React.Fragment>

      <hr />

      {loanInvestorData && (
        <React.Fragment>
          <h4>Inversion realizada</h4>

          <div className="row p-1 mx-1">
            <div className="col-2 rounded border p-1">Monto:</div>
            <div className="col-3 rounded border p-1 bg-light text-center">
              <strong><LoanAmount value={loanInvestorData.amount} /></strong>
            </div>

            {loanData.status === StatusEnum.APPROVED_CLIENT && (
            <div className="col-4">
              <button
                type="button"
                className="btn bg-danger p-1 text-white w-100"
                disabled={disabled}
                onClick={removeInvest}
                >
                Retirar inversion 
                {isRemovingInvest && (
                  <span 
                    className="spinner-border spinner-border-sm mx-2" 
                    role="status" aria-hidden="true"></span>
                )}
              </button>
            </div>
            )}
          </div>

          {loanData.status === StatusEnum.APPROVED_CLIENT && (
          <div className="row p-1 mx-1 mt-4">
            <div className="col-2 col-form-label">Monto:</div>
            <div className="col-3 p-0">
              <div className="input-group">
                <div className="input-group-text">$</div>
                <input 
                  type="text" 
                  className="form-control text-center" 
                  id="amount" 
                  aria-describedby="addHelp" 
                  onChange={(e) => setAddInvestAmount(e.target.value)}
                  value={addInvestAmount} />
              </div>
              <div id="addHelp" className="form-text text-end">
                min.: $100 max.: {"$" + (loanData.amount - loanData.invested_amount)}
              </div>
            </div>
            <div className="col-4">
              <button
                type="button"
                className="btn bg-bantex text-white w-100"
                disabled={disabled}
                onClick={addInvest}
                >
                Agregar inversion 
                {isAddingInvest && (
                  <span 
                    className="spinner-border spinner-border-sm mx-2" 
                    role="status" aria-hidden="true"></span>
                )}
              </button>
            </div>
          </div>
          )}

        </React.Fragment>
      )}

      {!loanInvestorData && (
        <React.Fragment>
          <h4>Realizar inversion</h4>

          <div className="row p-1 mx-1">
            <label htmlFor="amount" className="col-2 col-form-label">Monto:</label>
            <div className="col-4">
              <div className="input-group">
                <div className="input-group-text">$</div>
                <input 
                  type="number" 
                  min="100" step="1" max={maxInvestAmount}
                  value={investAmount} 
                  className="form-control" 
                  id="amount"
                  disabled={disabled}
                  aria-describedby="amountHelp" 
                  onChange={(e) => setInvestAmount(e.target.value)}
                  />
              </div>
              <div id="amountHelp" className="form-text text-end">
                min.: $100 max.: {"$" + (loanData.amount - loanData.invested_amount)}
              </div>
            </div>
            <div className="col-3">
              <button
                type="button"
                className="btn bg-bantex text-white w-100"
                disabled={disabled}
                onClick={submitApprove}
                >
                Aceptar 
                {isSubmittingData && (
                  <span 
                    className="spinner-border spinner-border-sm mx-2" 
                    role="status" aria-hidden="true"></span>
                )}
                </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default LoanInvestorAction;
