import React, {useState, useEffect} from 'react';

function CurrentBirthMonthDaysOptions(props) {

  const selectedMonth = props.selectedMonth;
  const monthDays = [-1,31,28,31,30,31,30,31,31,30,31,30,31]

  return (
    <React.Fragment>
      {Array.from(Array(monthDays[selectedMonth])).map( (v, i) => (
        <option value={i + 1} key={"day_" + (i + 1)}>{i + 1}</option>
      ))}
    </React.Fragment>
  );
};

function AvailableBirthYears(props) {

  let current_year = new Date().getFullYear();
  let max_year = current_year - 18;
  let min_year = max_year - 100;
  let year_range = Array(max_year - min_year + 1).fill().map((_, idx) => min_year + idx);

  return (
    <React.Fragment>
      {year_range.map( (v, i) => (
        <option value={v} key={"year_" + v}>{v}</option>
      ))}
    </React.Fragment>
  );
};

// date format: Y-m-d
function BirthdaySelect(props) {

  const id = props.id;
  const value = props.value;
  const onChange = props.onChange;

  const birthFormInitialState = {
    birth_month: 1,
    birth_day: 1,
    birth_year: parseInt(new Date().getFullYear()) - 18
  };
  const [birthForm, setBirthForm] = useState(birthFormInitialState);

  useEffect(() => {
    let isMounted = true;
    
    if( isMounted ){
      onChange(
        {
          target:
          {
            id:id, 
            value: birthForm.birth_year + '-' + birthForm.birth_month + '-' + birthForm.birth_day
          }
        });
    }
    
    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthForm]);

  useEffect(() => {
    let isMounted = true;
    
    if( isMounted ){
      if( value ){
        let date_split = value.split('-');
        setBirthForm({
          birth_year: date_split[0],
          birth_month: date_split[1],
          birth_day: date_split[2]
          });
      }
    }
    
    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (e) => {
    const {id , value} = e.target;
    setBirthForm(prevState => ({
        ...prevState,
        [id] : value
    }));
  }

  return (
    <React.Fragment>
      <div className="input-group">
        <select 
          className="form-select"
          id="birth_month" 
          value={birthForm.birth_month}
          onChange={handleChange}
          >
          <option value="1">Enero</option>
          <option value="2">Febrero</option>
          <option value="3">Marzo</option>
          <option value="4">Abril</option>
          <option value="5">Mayo</option>
          <option value="6">Junio</option>
          <option value="7">Julio</option>
          <option value="8">Agosto</option>
          <option value="9">Septiembre</option>
          <option value="10">Octubre</option>
          <option value="11">Noviembre</option>
          <option value="12">Diciembre</option>
        </select>
        <select 
          id="birth_day" 
          value={birthForm.birth_day}
          onChange={handleChange}
          className="form-select"
          >
          <CurrentBirthMonthDaysOptions
            selectedMonth={birthForm.birth_month}
            />
        </select>
        <select 
          id="birth_year" 
          value={birthForm.birth_year}
          onChange={handleChange}
          className="form-select"
          >
          <AvailableBirthYears />
        </select>
      </div>
    </React.Fragment>
  );
}

export default BirthdaySelect;