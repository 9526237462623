import React, {useState, useEffect} from 'react';

import { NotificationManager } from 'react-notifications';

function PidInput(props) {

  const id = props.id;
  const value = props.value;
  const onChange = props.onChange;
  const pidType = props.pidType;
  const required = props.required || false;

  const handleKeyUp = (e) => {

    var selection = window.getSelection().toString();

    if ( selection !== '' ) {
        return;
    }

    if ( [38,40,37,39].includes(e.keyCode) ) {
        return;
    }

    let input = e.target;
    let val = input.value;

    if( parseInt(pidType) === 1 )
      input.value = formatPIDNumber(val);

    else if( parseInt(pidType) === 2 )
      input.value = formatPassportNumber(val);

    else if( parseInt(pidType) === 3 )
      input.value = formatRUCNumber(val);

    else
      NotificationManager.error('Error validando datos.', 'Error');
  };

  const formatPIDNumber = (val) => {
    val = val.replace(/[^0-9]+/g, "");
    val = val.replace(/(\d{3})(?=(\d{3})+)/g, "$1-");
    return val;
  };

  const formatPassportNumber = (val) => {
    val = val.replace(/[^0-9a-zA-Z]+/g, "");
    return val;
  };

  const formatRUCNumber = (val) => {
    val = val.replace(/[^0-9]+/g, "");
    return val;
  };

  return (
    <input 
      type="text" 
      className="form-control" 
      id={id} 
      value={value}
      onKeyUp={handleKeyUp}
      onChange={onChange}
      placeholder="Nro. de Identificacion"
      required={required}
      />
  );
}

export default PidInput;