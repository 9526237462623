import React, {useState/*, useEffect*/} from 'react';

import { NotificationManager } from 'react-notifications';

import StatusEnum from '../page/StatusEnum';
import ApiService from '../services/ApiService';

function LoanClientAction(props) {

  const loanId = props.loanId;
  // const loanDetailData = props.loanDetailData;
  // const loanData = props.loanData;
  const setRefreshModal = props.setRefreshModal;
  const [disabled, setDisabled] = useState(false);

  return (
    <React.Fragment>

      <div className="row">

        <div className="col">
          <LoanClientActionReject 
            loanId={loanId}
            setRefreshModal={setRefreshModal}
            disabled={disabled}
            setDisabled={setDisabled}
            // setIsSubmittingData={setIsSubmittingData}
            // loanData={loanData}
            // loanDetail={loanDetailData}
            />
        </div>

        <div className="col">
          <LoanClientActionApprove 
            loanId={loanId}
            setRefreshModal={setRefreshModal} 
            disabled={disabled}
            setDisabled={setDisabled}
            // setIsSubmittingData={setIsSubmittingData}
            // loanData={loanData}
            // loanDetail={loanDetailData}
            />
        </div>

      </div>

    </React.Fragment>
    )
}

function LoanClientActionApprove(props) {

  const loanId = props.loanId;
  const setRefreshModal = props.setRefreshModal;
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const disabled = props.disabled;
  const setDisabled = props.setDisabled;

  const submitApprove = () => {
    setIsSubmittingData(true);
    setDisabled(true);
    
    ApiService.set_loan_status(loanId, StatusEnum.APPROVED_CLIENT)
      .then(
        (data) => {
          setIsSubmittingData(false);
          setRefreshModal(true);

          NotificationManager.success(
            'Se aprobo correctamente la propuesta');

        }, error => {
          setIsSubmittingData(false);
          NotificationManager.error(
            'Ocurrio un error aprobando la propuesta. Por favor intente nuevamente.', 'Error');
        });

  }

  return (
    <React.Fragment>
      <hr />

      <h5 className="text-center">Aprobar propuesta</h5>

      <div className="row">
        <div className="col text-center">
          <button
            type="button"
            className="btn bg-bantex text-white w-75" 
            disabled={disabled}
            onClick={submitApprove}
            >
              Aprobar&nbsp;
              {isSubmittingData && (
                <span 
                  className="spinner-border spinner-border-sm mx-2" 
                  role="status" aria-hidden="true"></span>
              )}
            </button>
        </div>
      </div>
    </React.Fragment>
  )
}

function LoanClientActionReject(props) {

  const loanId = props.loanId;
  const setRefreshModal = props.setRefreshModal;
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const disabled = props.disabled;
  const setDisabled = props.setDisabled;

  const submitReject = () => {
    setIsSubmittingData(true);
    setDisabled(true);

    ApiService.set_loan_status(loanId, StatusEnum.REJECTED_CLIENT)
      .then(
        (data) => {
          setIsSubmittingData(false);
          setRefreshModal(true);

          NotificationManager.success(
            'Se rechazo correctamente la propuesta');

        }, error => {
          setIsSubmittingData(false);
          NotificationManager.error(
            'Ocurrio un error rechazando la propuesta. Por favor intente nuevamente.', 'Error');
        }
      );

  }

  return (
    <React.Fragment>
      <hr />

      <h5 className="text-center">Rechazar propuesta</h5>

      <div className="row">
        <div className="col text-center">
          <button
            type="button"
            className="btn btn-danger w-75" 
            disabled={disabled} 
            onClick={submitReject}
            >
              Rechazar&nbsp;
              {isSubmittingData && (
                <span 
                  className="spinner-border spinner-border-sm mx-2" 
                  role="status" aria-hidden="true"></span>
              )}
            </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LoanClientAction;
