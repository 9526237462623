import React, {useState, useMemo, useEffect} from 'react';

import { NotificationManager } from 'react-notifications';

import TableBasic from './TableBasic';
import ApiSelector from '../services/ApiSelector';
import LoanDate from './LoanDate';
import LoanAmount from './LoanAmount';

function LoanInvestor(props){

  const loanId = props.loanId;
  // const loanDetailData = props.loanDetailData;
  // const loanData = props.loanData;
  // const setRefreshModal = props.setRefreshModal;

  const columns = useMemo(
    () => [
      {
        Header: 'Fecha',
        // headerClassName: 'table-basic-w-created',
        cellClassName: 'align-middle',
        Cell: props => <LoanDate value={props.row.original.created} />
      },
      {
        Header: 'Usuario',
        // headerClassName: 'table-basic-w-created',
        cellClassName: 'align-middle',
        Cell: props => props.row.original.user.username
      },
      {
        Header: 'Monto',
        // headerClassName: 'table-basic-w-field-1',
        cellClassName: 'align-middle',
        Cell: props => <LoanAmount value={props.row.original.amount} />
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  return (
    <React.Fragment>
      <hr />

      <h4>Detalle de inversores</h4>

      <LoanInvestorList 
        loanId={loanId} 
        columns={columns} />

    </React.Fragment>
  );
}

function LoanInvestorList(props){

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [data, setData] = useState([]);

  const loanId = props.loanId;
  const columns = props.columns;

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){
      ApiSelector.loan_investor(loanId)
        .then(
          (data) => {
            // console.log(data);
            setIsLoadingData(false);
            setData(data);
          }, error => {
            // console.log(error);
            setIsLoadingData(false);
            NotificationManager.error(
              'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
          });
    }
    
    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoData = useMemo(
    () => data, [data]
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <TableBasic  
            className="table table-hover" 
            isLoading={isLoadingData} 
            columns={columns} data={memoData} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default LoanInvestor;
