import axios from "axios";
import { decodeToken } from "react-jwt";

class AuthService {
  static API_LOGIN = "http://srvarg001.tuscombis.com:8080/api/v1/auth/";
  // static API_LOGIN = "http://localhost:8000/api/v1/auth/";

  login(u, p) {
    return axios.post(
      AuthService.API_LOGIN,
      { username: u, password: p }
    ).then( response => {
      // console.log(response);
      if( response.status === 200
          && response.data.access 
          && response.data.refresh ){

        const d_token = decodeToken(response.data.access);
        localStorage.setItem('user', JSON.stringify({
          refresh_token: response.data.refresh,
          access_token: response.data.access,
          user_id: d_token.user_id,
          username: d_token.username,
          type: d_token.type
        }));
      }
      return response.data;
    });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.clear();
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

}

export default new AuthService();