import React, {useState, useEffect} from 'react';

import { NotificationManager } from 'react-notifications';

import ApiAdminService from '../services/ApiAdminService';
import LoanDate from './LoanDate';
import LoanId from './LoanId';

function ClientModal(props){

  const profileId = props.profileId;

  const [isLoadingData, setIsLoadingData] = useState(0);
  const [profileData, setProfileData] = useState(null);
  const [clientProfileData, setClientProfileData] = useState(null);

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){

      if( profileId > 0 ){
        setIsLoadingData(2); // sumamos los dos request asi esperamos a que terminen los dos
        get_profile_info(profileId);
        get_client_profile_info(profileId);

      }else
        setProfileData(null);
        setClientProfileData(null);
    }
    
    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const get_profile_info = (id) => {
    ApiAdminService.profile_info(id)
      .then(
        (data) => {
          console.log(data);
          setProfileData(data);
          setIsLoadingData( prevState => (prevState - 1));

        }, error => {
          setIsLoadingData(isLoadingData - 1);
          NotificationManager.error(
            'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
        });
  }

  const get_client_profile_info = (id) => {
    ApiAdminService.client_info(id)
      .then(
        (data) => {
          // console.log(data);
          setClientProfileData(data);
          setIsLoadingData(prevState => (prevState - 1));

        }, error => {
          setIsLoadingData(isLoadingData - 1);
          NotificationManager.error(
            'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
        });
  }

  return (
    <React.Fragment>
      <h5>Informacion del usuario</h5>

      {isLoadingData > 0 && (
        <div 
          className="spinner-border float-end spinner-border-sm mt-3 me-2 text-bantex" 
          role="status" aria-hidden="true"></div>
      )}

      {isLoadingData <= 0 && profileData && (
        <React.Fragment>
          <div className="row p-1 mx-1">

            <div className="col-1 rounded border p-1">ID:</div>
            <div className="col-2 rounded border bg-light p-1 text-center">
              <strong><LoanId value={profileData.user.id} /></strong>
            </div>

            <div className="col-2 rounded offset-1 border p-1">E-mail:</div>
            <div className="col rounded border bg-light p-1 text-center">
              <strong>{profileData.user.email}</strong>
            </div>
          </div>

          <div className="row p-1 mx-1">
            <div className="col-3 rounded border p-1">Fecha de alta:</div>
            <div className="col-2 rounded border bg-light p-1 text-center">
              <strong><LoanDate value={profileData.user.date_joined} /></strong>
            </div>

            <div className="col-2 rounded offset-1 border p-1">Usuario:</div>
            <div className="col rounded border bg-light p-1 text-center">
              <strong>{profileData.user.username}</strong>
            </div>
          </div>

        </React.Fragment>
      )}

    </React.Fragment>
  )
}

export default ClientModal;