import React, {useState, useEffect} from 'react';
import {
  Link
} from "react-router-dom";
import { NotificationManager } from 'react-notifications';

import AuthService from '../../services/AuthService';
import ApiService from '../../services/ApiService';

import LoanRequestReasonSelect from '../../components/LoanRequestReasonSelect';
import PidInput from '../../components/PidInput';
import BirthdaySelect from '../../components/BirthdaySelect';
import CountrySelect from '../../components/CountrySelect';

function LoanForm(props) {

  const setBreadcrumbs = props.setBreadcrumbs;

  const loanFormInitialState = {
    reason: 1,
    reason_other: '',
    amount: 0.00,
    // bailor data
    // bailor: 0,
    // pid_type: 1,
    // pid: ''
  };
  const [loanForm, setLoanForm] = useState(loanFormInitialState);
  const [loanStep, setLoanStep] = useState(1);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showReasonOther, setShowReasonOther] = useState(false);

  useEffect(() => {
      setBreadcrumbs([{title:'Inicio', to:'/home'}, {title:'Nueva solicitud'}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;
    
    if( isMounted ){
      if( 
        parseInt(loanForm.reason) === 5 
        || parseInt(loanForm.reason) === 6
        || parseInt(loanForm.reason) === 7
        || parseInt(loanForm.reason) === 11 ) {

        setShowReasonOther(true);

      }else
        setShowReasonOther(false);
    }
    
    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanForm]);

  // const handleKeyUpPid = (e) => {

  //   var selection = window.getSelection().toString();
  //     if ( selection !== '' ) {
  //         return;
  //     }

  //     if ( [38,40,37,39].includes(e.keyCode) ) {
  //         return;
  //     }   

  //   let input = e.target;
  //   let val = input.value;

  //   if( parseInt(loanForm.pid_type) === 1 )
  //     input.value = formatPIDNumber(val);

  //   else if( parseInt(loanForm.pid_type) === 2 )
  //     input.value = formatPassportNumber(val);

  //   else if( parseInt(loanForm.pid_type) === 3 )
  //     input.value = formatRUCNumber(val);

  //   else
  //     NotificationManager.error('Error validando datos.', 'Error');
  // };

  // const formatPIDNumber = (val) => {
  //     val = val.replace(/[^0-9]+/g, "");
  //   val = val.replace(/(\d{3})(?=(\d{3})+)/g, "$1-");
  //   return val;
  // };

  // const formatPassportNumber = (val) => {
  //     val = val.replace(/[^0-9a-zA-Z]+/g, "");
  //   return val;
  // };

  // const formatRUCNumber = (val) => {
  //     val = val.replace(/[^0-9]+/g, "");
  //   return val;
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    let error = false;

    if (!form.checkValidity()) {
        error = true;
    }

    // los datos vienen del state
    // form validation

    form.classList.add('was-validated');

    if( !error ){
      setIsSubmiting(true)

      ApiService.create_loan({...loanForm, user: AuthService.getCurrentUser().user_id})
        .then(
          () => {
            setIsSubmiting(false);
            NotificationManager.success('Solicitud creada con exito!');
            setLoanStep(2);
          },
          error => {
            setIsSubmiting(false);
            // console.log(error.response);
            NotificationManager.error('Por favor, verifique los datos ingresados.', 'Error');
          }
        );
    }
  }

  const handleChange = (e) => {
    const {id , value} = e.target;
    setLoanForm(prevState => ({
        ...prevState,
        [id] : value
    }));
  }

  // const handleAmountKeyUp = (e) => {

  //   var selection = window.getSelection().toString();

  //   if ( selection !== '' ) {
  //       return;
  //   }

  //   if ( [38,40,37,39].includes(e.keyCode) ) {
  //       return;
  //   }   

  //   let input = e.target;
  //   let val = input.value;

  //   val = val.replace(/[^0-9]+/g, "");
  //   val = val ? parseInt( val, 10 ) : 0;
  //   val = val === 0 ? "0" : val.toLocaleString("en-US");
  //   setLoanForm(prevState => ({
  //     ...prevState,
  //     [input.id]: val
  //   }))
  // }

  const submitScreen = (
    <React.Fragment>
      <h3>Nueva solicitud de prestamo</h3>
      <p>Tu solicitud fue enviada con exito.<br />
      Recibiras un email con el resultado.</p>
      <p><Link to="/client/requests">Inicio</Link></p>
    </React.Fragment>
  )

  const loanFormHTML = (<React.Fragment>

    <h3>Nueva solicitud de prestamo</h3>

    <form onSubmit={handleSubmit} noValidate>

      <div className="row mb-3">
        <label htmlFor="campo1" className="col-sm-4 col-form-label">Motivo de solicitud</label>
        <div className="col-sm-8">
          <LoanRequestReasonSelect
            required={true}
            handleChange={handleChange}
            value={loanForm.reason} />

          <div className="invalid-feedback">Debe indicar el motivo de la solicitud.</div>
        </div>
      </div>

      {showReasonOther && (
        <div className="row mb-3">
          <label htmlFor="campo2" className="col-sm-4 col-form-label">Detallar motivo</label>
          <div className="col-sm-8">
            <input 
              type="text" 
              className="form-control" 
              id="reason_other" 
              value={loanForm.reason_other}
              onChange={handleChange}
              aria-describedby="reasonOtherHelp" 
              required 
              />
            <div className="invalid-feedback">Por favor complete este campo.</div>
            <div id="reasonOtherHelp" className="form-text">
              Por favor, detalle el motivo seleccionado.
            </div>
            <div className="invalid-feedback">Debe detallar el motivo de la solicitud.</div>
          </div>
        </div>
      )}

      <div className="row mb-3">
        <label htmlFor="amount" className="col-sm-4 col-form-label">Monto solicitado</label>
        <div className="col-sm-8">
          <div className="input-group">
            <div className="input-group-text">$</div>
            <input 
              type="number" 
              className="form-control" 
              id="amount" 
              value={loanForm.amount}
              onChange={handleChange}
              min="500"
              // onKeyUp={handleAmountKeyUp}
              aria-describedby="montoHelp" 
              required 
              />
            <div className="invalid-feedback">Por favor complete este campo. Monto minimo: $500</div>
          </div>
          <div id="montoHelp" className="form-text">
            Min: $500 / Valor expresado en dolares (u$d)
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md-auto">
          <span>Desea incluir un fiador para la solicitud?</span>
        </div>
        <div className="col-md">
          <div className="btn-group" role="group" aria-label="Vehiculo">
            <input 
              type="radio" 
              className="btn-check btn-check-bantex" 
              name="bailor" 
              id="bailoryes" 
              onChange={(e) => handleChange({target:{id:'bailor', value:1}})}
              value="1" autoComplete="off" />

            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="bailoryes">Si</label>

            <input 
              type="radio" 
              className="btn-check btn-check-bantex" 
              name="bailor" 
              id="bailorno" 
              onChange={(e) => handleChange({target:{id:'bailor', value:0}})}
              value="2" autoComplete="off" defaultChecked={true} />

            <label className="btn btn-outline-primary btn-outline-bantex" htmlFor="bailorno">No</label>
          </div>
        </div>
      </div>

      {loanForm.bailor === 1 && (
      <React.Fragment>
      <div className="row mb-3">
        <div className="col bg-bantex text-white">
          <span>Datos del fiador</span>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-3">
          <div className="form-floating mb-2">
            <select 
              className="form-select" 
              id="gender" 
              value={loanForm.gender}
              onChange={handleChange}
              placeholder="Sexo">
              <option value="1">Hombre</option>
              <option value="2">Mujer</option>
            </select>
            <label htmlFor="gender">Sexo</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="name" 
              value={loanForm.name}
              onChange={handleChange}
              placeholder="Nombre/s"
              required 
              />
            <label htmlFor="name">Nombre/s</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-6">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="lastname_first" 
              value={loanForm.lastname_first}
              onChange={handleChange}
              placeholder="Primer Apellido"
              required 
              />
            <label htmlFor="lastname_first">Primer Apellido</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="lastname_second" 
              value={loanForm.lastname_second}
              onChange={handleChange}
              placeholder="Segundo Apellido"
              required 
              />
            <label htmlFor="lastname_second">Segundo Apellido</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <div className="col-md-4">
          <div className="form-floating mb-2">
            <select 
              className="form-select" 
              id="pid_type" 
              placeholder="Tipo de Identificacion"
              onChange={handleChange}
              value={loanForm.pid_type} 
              >
              <option value="1">Cedula</option>
              <option value="2">Pasaporte</option>
              <option value="3">Residencia</option>
            </select>
            <label htmlFor="pid_type">Tipo de Identificacion</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <PidInput 
              id="pid" 
              value={loanForm.pid}
              onChange={handleChange}
              pidType={loanForm.pid_type}
              required={true}
              />
            <label htmlFor="pid">Nro. de Identificacion</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <label htmlFor="borndate" className="col-md-6 form-col-label px-3">Fecha de nacimiento</label>
        <div className="col-md">
          <BirthdaySelect
            id="birthdate"
            onChange={handleChange}
            value={loanForm.birthdate} />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
          
            <CountrySelect 
              value={loanForm.country}
              handleChange={handleChange}
              />

            <label htmlFor="country">Nacionalidad</label>
          </div>
        </div>

        <div className="col-md">
          <div className="form-floating mb-2">
            <select 
              className="form-select" 
              id="marital_status" 
              value={loanForm.marital_status}
              onChange={handleChange}
              placeholder="Estado Civil"
              >
              <option value="1">Soltero</option>
              <option value="2">Casado</option>
              <option value="3">Divorciado</option>
              <option value="4">Viudo</option>
            </select>
            <label htmlFor="marital_status">Estado Civil</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="address" 
              value={loanForm.address}
              onChange={handleChange}
              placeholder="Direccion de residencia"
              required />
            <label htmlFor="address">Direccion de residencia</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="tel_home" 
              value={loanForm.tel_home}
              onChange={handleChange}
              placeholder="Telefono de residencia"
              required />
            <label htmlFor="tel_home">Telefono de residencia</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="tel_mobile" 
              value={loanForm.tel_mobile}
              onChange={handleChange}
              placeholder="Telefono celular"
              required />
            <label htmlFor="tel_mobile">Telefono celular</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="email" 
              value={loanForm.email}
              onChange={handleChange}
              placeholder="Correo electronico"
              required />
            <label htmlFor="address">Correo electronico</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col bg-bantex text-white">
          <span>Datos laborales del fiador</span>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-7">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="work_place" 
              value={loanForm.email}
              onChange={handleChange}
              placeholder="Lugar de trabajo"
              required />
            <label htmlFor="work_place">Lugar de trabajo</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="work_position" 
              value={loanForm.email}
              onChange={handleChange}
              placeholder="Puesto"
              required />
            <label htmlFor="work_position">Puesto</label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="work_salary" 
              value={loanForm.email}
              onChange={handleChange}
              placeholder="Salario actual"
              required />
            <label htmlFor="work_salary">Salario actual</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-2">
            <input 
              type="text" 
              className="form-control" 
              id="work_tel" 
              value={loanForm.email}
              onChange={handleChange}
              placeholder="Telefono"
              required />
            <label htmlFor="work_tel">Telefono</label>
          </div>
        </div>
      </div>

      <div className="row mb-3 align-items-center">
        <label htmlFor="borndate" className="col-md-6 form-col-label">Fecha de ingreso</label>
        <div className="col-md">
          <div className="input-group">
            <select className="form-select">
              <option value="1">Agosto</option>
            </select>
            <select className="form-select">
              <option value="1">18</option>
            </select>
            <select className="form-select">
              <option value="1">1984</option>
            </select>
          </div>
        </div>
      </div>

      </React.Fragment>
      )}

      <div className="container-fluid justify-content-center">
        <button 
          type="submit" 
          className="btn bg-bantex text-white w-100"
          disabled={isSubmiting}
          >
          Enviar solicitud
          {isSubmiting &&
            (
              <span 
                className="spinner-border spinner-border-sm mx-2" 
                role="status" aria-hidden="true"></span>
            )}
        </button>
      </div>

    </form>
  </React.Fragment>
  )

  return (<React.Fragment>
    <div className="container bg-light border rounded-3 mt-3 p-3" style={{ width: '700px' }}>
      {loanStep === 2 ? submitScreen : loanFormHTML}
    </div>
  </React.Fragment>)
}

export default LoanForm;