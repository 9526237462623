import React, { useEffect } from 'react';

function Home(props){
  const setBreadcrumbs = props.setBreadcrumbs;

  useEffect(() => {
      setBreadcrumbs([{title:'Home'}]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="container-fluid">
        <h5 className="pt-2">Dashboard</h5>
      </div>
    </React.Fragment>
  );
}

export default Home;