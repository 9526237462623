import React from 'react';
import { useTable } from 'react-table'

import TablePagination from './TablePagination';

function TableBasic({ 
  className, 
  isLoading, 
  columns, data, 
  setCurrentPage, resultsCount, currentPage }){

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <React.Fragment>
    <table className={className} {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps({ className: column.headerClassName })}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>

        {isLoading && (
          <tr className="table-basic-no-rows">
            <td colSpan="100">
              <div className="d-flex align-items-center">
                <div className="spinner-border spinner-border-sm text-secondary ms-auto" role="status" aria-hidden="true"></div>
              </div>
            </td>
          </tr>
        )}

        {!isLoading && rows.length <= 0 && (
          <tr className="table-basic-no-rows">
            <td colSpan="100">No se encontraron resultados.</td>
          </tr>
        )}

        {!isLoading && rows.length > 0 && rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps({ className: cell.column.cellClassName })}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>

    {resultsCount > 0 && currentPage && setCurrentPage && (
      <TablePagination 
        resultsCount={resultsCount} 
        currentPage={currentPage} 
        setCurrentPage={setCurrentPage}
        />
    )}

    </React.Fragment>
  )

}

export default TableBasic;