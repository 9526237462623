import React, {useState, useEffect, useMemo} from 'react';
// import {
//   Link
// } from "react-router-dom";
import ReactModal from 'react-modal';

import { NotificationManager } from 'react-notifications';

import TableBasic from '../../components/TableBasic';
import LoanStatus from '../../components/LoanStatus';
import LoanStatusSelect from '../../components/LoanStatusSelect';
import LoanAmount from '../../components/LoanAmount';
import LoanDate from '../../components/LoanDate';
import LoanModal from '../../components/LoanModal';
import ClientModal from '../../components/ClientModal';
import ApiAdminService from '../../services/ApiAdminService';
import LoanRequestReason from '../../components/LoanRequestReason';

ReactModal.setAppElement('#root');

function AdminLoans(props){

  const setBreadcrumbs = props.setBreadcrumbs;

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [reqLoanList, setReqLoanList] = useState([]);
  const [reqLoanListCount, setReqLoanListCount] = useState(0);
  const [loanStatus, setLoanStatus] = useState(1);
  
  const [loanViewId, setLoanViewId] = useState(null);
  const [openLoanModal, setOpenLoanModal] = useState(false);
  
  const [profileViewId, setProfileViewId] = useState(null);
  const [openClientModal, setOpenClientModal] = useState(false);

  const [doRefresh, setDoRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const modalStyles = {
    content: {
      minWidth: '650px',
      maxWidth: '650px',
      // width: '80%',
      height: '85%',
      top: '50%',
      left: '50%',
      // right: '10%',
      // bottom: '10%',
      // marginRight: '50%',
      // marginBottom: '-30%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {zIndex: 1000}
  };

  useEffect( () => {
    setBreadcrumbs([{title:'Inicio', to:'/home'}, {title:'Prestamos'}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){
      setIsLoadingData(true);
      get_loans(loanStatus, currentPage);
    }
    
    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){
      setIsLoadingData(true);
      setCurrentPage(1);
      get_loans(loanStatus, 1);
    }
    
    return () => { setDoRefresh(false); isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanStatus, doRefresh]);

  const get_loans = (status, page) => {
    ApiAdminService.loans(status, page)
      .then(
        (data) => {
          // console.log(data);
          setIsLoadingData(false);
          setReqLoanList(data.results);
          setReqLoanListCount(data.count);
        }, error => {
          // console.log(error);
          setIsLoadingData(false);
          setReqLoanListCount(0);
          setReqLoanList(null);
          NotificationManager.error(
            'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
        });
  }

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accesor: 'id',
        headerClassName: 'table-basic-w-id',
        cellClassName: 'align-middle',
        Cell: props => props.row.original.id
      },
      {
        Header: 'Motivo',
        accesor: 'reason',
        headerClassName: 'table-basic-w-field-1',
        cellClassName: 'align-middle',
        Cell: props => <LoanRequestReason reason={props.row.original.reason} />
      },
      // {
      //   Header: 'Campo1',
      //   accesor: 'campo1',
      //   headerClassName: 'table-basic-w-field-1',
      //   cellClassName: 'align-middle',
      //   Cell: props => props.row.original.campo1
      // },
      // {
      //   Header: 'Campo2',
      //   accesor: 'campo2',
      //   cellClassName: 'align-middle',
      //   Cell: props => props.row.original.campo2
      // },
      // {
      //   Header: 'Campo3',
      //   accesor: 'campo3',
      //   cellClassName: 'align-middle',
      //   Cell: props => props.row.original.campo3
      // },
      {
        Header: 'Monto',
        accesor: 'amount',
        cellClassName: 'align-middle',
        Cell: props => <LoanAmount value={props.row.original.amount} />
      },
      {
        Header: 'Inversion',
        accesor: 'invested_amount',
        cellClassName: 'align-middle',
        Cell: props => <LoanAmount value={props.row.original.invested_amount} />
      },
      {
        Header: 'Cliente',
        accesor: row => row.original.user.username,
        cellClassName: 'align-middle',
        Cell: props => <button 
                          type="button" 
                          className="btn btn-sm bg-bantex text-white" 
                          onClick={(e) => showClientModal(props.row.original.user.id)}
                          >{props.row.original.user.username}</button>
      },
      // {
      //   Header: 'Estado',
      //   accesor: 'status',
      //   headerClassName: 'table-basic-w-status',
      //   // cellClassName: 'text-warning',
      //   Cell: props => <LoanStatus status={props.row.original.status} />
      // },
      {
        Header: 'Creado',
        accesor: 'created',
        headerClassName: 'table-basic-w-created',
        cellClassName: 'align-middle',
        Cell: props => <LoanDate value={props.row.original.created} />
      },
      {
        Header: ' ',
        accesor: row => '',
        headerClassName: 'table-basic-w-actions',
        cellClassName: 'align-middle',
        Cell: props => 
          <React.Fragment>
            <div className="d-flex flex-row align-items-center">
              <LoanStatus status={props.row.original.status} />
              <button 
                type="button" 
                className="btn btn-sm p-1" 
                onClick={(e) => showLoanModal(props.row.original.id)}
                ><img alt="+info" title="Mas Informacion" src="/images/info.png" /></button>
            </div>
          </React.Fragment>
      }
    ],
    []
  );

  const showLoanModal = (id) => {
    setLoanViewId(id);
    setOpenLoanModal(true);
  }

  const showClientModal = (id) => {
    setProfileViewId(id);
    setOpenClientModal(true);
  }

  const memoLoans = useMemo(
    () => reqLoanList, [reqLoanList]);
  
  return (
    <React.Fragment>
      
      <div className="container-fluid">
        
        <div className="row mt-2">
          <label htmlFor="amount" className="col-1 col-form-label">Estado:</label>
          <div className="col-3">
            <LoanStatusSelect 
              onChange={(value) => setLoanStatus(value)} />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <TableBasic  
              className="table table-hover" 
              isLoading={isLoadingData} 
              columns={columns} data={memoLoans} 
              resultsCount={reqLoanListCount} 
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage} 
              />
          </div>
        </div>

      </div>



      <ReactModal
        isOpen={openLoanModal}
        onRequestClose={() => setOpenLoanModal(false)} 
        style={modalStyles} >

          <div className="float-end">
            <button 
              type="button" 
              className="btn-close btn-sm btn-danger bg-bantex text-white p-1"
              onClick={() => setOpenLoanModal(false)}></button>
          </div>

          <LoanModal 
            loanId={loanViewId}
            setDoRefreshMain={setDoRefresh} />

      </ReactModal>

      <ReactModal
        isOpen={openClientModal}
        onRequestClose={() => setOpenClientModal(false)}
        style={modalStyles} >

          <div className="float-end">
            <button 
              type="button" 
              className="btn-close btn-sm btn-danger bg-bantex text-white p-1"
              onClick={() => setOpenClientModal(false)}></button>
          </div>
        
          <ClientModal 
            profileId={profileViewId} />

      </ReactModal>

    </React.Fragment>
    )
}

export default AdminLoans;