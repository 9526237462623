function LoanPeriodSelect(props) {

  const onChange = props.onChange;
  const disabled = props.disabled || null;
  const value = props.value || '';

  return (
    <select 
      className="form-select" 
      name="loan_status"
      disabled={disabled || false}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      >
        <option value="">Seleccionar...</option>
        <option value="6">6 meses</option>
        <option value="12">12 meses</option>
        <option value="18">18 meses</option>
        <option value="24">24 meses</option>
        <option value="30">30 meses</option>
        <option value="36">36 meses</option>
        <option value="42">42 meses</option>
        <option value="48">48 meses</option>
        <option value="54">54 meses</option>
        <option value="60">60 meses</option>
        <option value="66">66 meses</option>
        <option value="72">72 meses</option>
        <option value="78">78 meses</option>
        <option value="84">84 meses</option>
    </select>
  )

}

export default LoanPeriodSelect;