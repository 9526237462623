import axios from "axios";
import AuthService from './AuthService';

class ApiService {

  static API_BASE_URL = "http://srvarg001.tuscombis.com:8080/api/v1";
  // static API_BASE_URL = "http://localhost:8000/api/v1";

  static API_CONFIG = {
    "API_REGISTER":       this.API_BASE_URL + "/signup/",
    "API_LOAN_AVAILABLE": this.API_BASE_URL + "/%T%/loan/available/",
    "API_LOAN":           this.API_BASE_URL + "/%T%/loan/",
    "API_LOAN_PAYOUT":    this.API_BASE_URL + "/%T%/loan-payout/",
    "API_LOAN_DETAIL":    this.API_BASE_URL + "/%T%/loan-detail/",
    "API_LOAN_INVESTOR":  this.API_BASE_URL + "/%T%/loan-investor/",
    "API_LOAN_PAY":       this.API_BASE_URL + "/%T%/loan-pay/",
    "API_PROFILE":        this.API_BASE_URL + "/%T%/profile/",
    "API_CLIENT":         this.API_BASE_URL + "/%T%/client-profile/",
    "API_INVESTOR":       this.API_BASE_URL + "/%T%/investor-profile/",
  }

  // private?
  get_user_url(url) {
    const logged_user = AuthService.getCurrentUser();

    if( !logged_user )
      return null;

    let t = logged_user.type === 1 ? 'client' : 'investor';

    return url.replace('%T%', t);
  }

  register(data) {
    return axios.post(ApiService.API_CONFIG.API_REGISTER, data)
      .then( response => response.data );
  }

  loans(status=null, page=1) {
    let status_filter = '';

    if( status ){
      status_filter = status.map( (v, i) => (
        "status=" + v
      ));
      status_filter = '?' + status_filter.join('&');
    }

    return axios.get(this.get_user_url(ApiService.API_CONFIG.API_LOAN) + status_filter + '&page=' + page)
      .then( response => response.data );
  }

  available_loans(page=1) {
    return axios.get(this.get_user_url(ApiService.API_CONFIG.API_LOAN_AVAILABLE + '?page=' + page))
      .then( response => response.data );
  }

  create_loan(data) {
    return axios.post(this.get_user_url(ApiService.API_CONFIG.API_LOAN), data)
      .then( response => response.data );
  }

  set_loan_status(id, new_status) {
    return axios.post(this.get_user_url(ApiService.API_CONFIG.API_LOAN + id + '/status/'), {status:new_status})
      .then( response => response.data );
  }

  loan_add_invest(id, amount) {
    return axios.put(this.get_user_url(ApiService.API_CONFIG.API_LOAN + id + '/edit/'), {amount:amount})
      .then( response => response.data );
  }

  loan_remove_invest(id) {
    return axios.delete(this.get_user_url(ApiService.API_CONFIG.API_LOAN + id + '/delete/'))
      .then( response => response.data );
  }

  loan_accept(id, amount) {
    return axios.post(this.get_user_url(ApiService.API_CONFIG.API_LOAN + id + '/accept/'), {amount:amount})
      .then( response => response.data );
  }

  loan_info(id) {
    return axios.get(this.get_user_url(ApiService.API_CONFIG.API_LOAN + id))
      .then( response => response.data );    
  }

  loan_payout(id, status=null) {
    
    let status_filter = '';
    if( status ){
      status_filter = status.map( (v, i) => (
        "&status=" + v
      ));
      status_filter = status_filter.join('');
    }

    return axios.get(this.get_user_url(ApiService.API_CONFIG.API_LOAN_PAYOUT + '?loan=' + id + status_filter))
      .then( response => response.data );    
  }

  loan_pay(id) {
    return axios.post(this.get_user_url(ApiService.API_CONFIG.API_LOAN_PAY + id + '/pay/'))
      .then( response => response.data );
  }

  loan_detail_info(id) {
    return axios.get(this.get_user_url(ApiService.API_CONFIG.API_LOAN_DETAIL + id))
      .then( response => response.data );
  }

  invested_loans(status=null, page=1) {

    let status_filter = '';
    if( status ){
      status_filter = status.map( (v, i) => (
        "&status=" + v
      ));
      status_filter = status_filter.join('');
    }

    return axios.get(this.get_user_url(ApiService.API_CONFIG.API_LOAN_INVESTOR + '?page=' + page + status_filter))
      .then( response => response.data );
  }

  loan_investor(id) {
    return axios.get(this.get_user_url(ApiService.API_CONFIG.API_LOAN_INVESTOR) + '?loan=' + id)
      .then( response => response.data );
  }

  profile(id) {
    return axios.get(this.get_user_url(ApiService.API_CONFIG.API_PROFILE + id))
      .then( response => response.data );
  }

  client_info(id) {
    return axios.get(this.get_user_url(ApiService.API_CONFIG.API_CLIENT + id))
      .then( response => response.data );
  }

  investor_info(id) {
    return axios.get(this.get_user_url(ApiService.API_CONFIG.API_INVESTOR + id))
      .then( response => response.data );
  }

}

export default new ApiService();
