const StatusEnum = {
  PENDING_BANTEX:   1,
  PENDING_CLIENT:   2,
  APPROVED_CLIENT:  3,
  REJECTED_BANTEX:  4,
  REJECTED_CLIENT:  5,
  INVEST_COMPLETED: 6,
  PAYOUT:           7,
  ENDED:            8
};

export default StatusEnum;