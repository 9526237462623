import React, {useState} from 'react';
import {
  Link
} from "react-router-dom";

import AuthService from '../services/AuthService';
import Breadcrumbs from './Breadcrumbs';

function Header(props) {

  const [loggedUser, setLoggedUser] = useState(AuthService.getCurrentUser() || null);

  const breadcrumbs = props.breadcrumbs;

  const handleLogout = () => {
    setLoggedUser(null);
    AuthService.logout();
    window.location.reload(true);
  }

  const navBar = loggedUser ? (
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

          <li className="nav-item">
            <Link to="/home" className="nav-link link-bantex">
              <img alt="Inicio" title="Inicio" src="/images/inicio.png" />
            </Link>
          </li>

          {loggedUser.type === 0 && 
            (<React.Fragment>

              <li className="nav-item">
                <div className="dropdown">
                  <Link 
                    to="#" 
                    className="nav-link link-bantex adropdown-toggle"
                    role="button" id="dropdownLoans" data-bs-toggle="dropdown" aria-expanded="false">
                    <img alt="Prestamos" title="Prestamos" src="/images/prestamos.png" />
                  </Link>

                  <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownLoans">
                    <li>
                      <Link to="/admin/loans" className="dropdown-item">Prestamos</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <Link to="/admin/users" className="nav-link link-bantex">
                  <img alt="Usuarios" title="Usuarios" src="/images/usuarios.png" />
                </Link>
              </li>

            </React.Fragment>
            )}

          {loggedUser.type === 1 && 
            (<React.Fragment>

              <li className="nav-item">
                <div className="dropdown">
                  <Link 
                    to="#" 
                    className="nav-link link-bantex"
                    role="button" id="dropdownLoans" data-bs-toggle="dropdown" aria-expanded="false">
                    <img alt="Prestamos" title="Prestamos" src="/images/prestamos.png" />
                  </Link>

                  <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownLoans">
                    <li>
                      <Link to="/client/requests" className="dropdown-item">Mis solicitudes</Link>
                    </li>
                    <li>
                      <Link to="/client/approved-loans" className="dropdown-item">Prestamos aprobados</Link>
                    </li>
                    <li>
                      <Link to="/client/loans" className="dropdown-item">Prestamos desembolsados</Link>
                    </li>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                      <Link to="/client/loan/create" className="dropdown-item">Nueva solicitud</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <Link to="/wallet" className="nav-link link-bantex">
                  <img alt="Billetera" title="Billetera" src="/images/wallet.png" />
                </Link> 
              </li>

              <li className="nav-item">
                <Link to="/my-account" className="nav-link link-bantex">
                  <img alt="Mi cuenta" title="Mi Cuenta" src="/images/mi_cuenta.png" />
                </Link>
              </li>

            </React.Fragment>
            )}

          {loggedUser.type === 2 && 
            (<React.Fragment>

              <li className="nav-item">
                <div className="dropdown">
                  <Link 
                    to="#" 
                    className="nav-link link-bantex adropdown-toggle"
                    role="button" id="dropdownLoans" data-bs-toggle="dropdown" aria-expanded="false">
                    <img alt="Prestamos" title="Prestamos" src="/images/prestamos.png" />
                  </Link>

                  <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownLoans">
                    <li>
                      <Link to="/investor/loans" className="dropdown-item">Inversiones disponibles</Link>
                    </li>
                    <li>
                      <Link to="/investor/invested-loans" className="dropdown-item">Inversiones realizadas</Link>
                    </li>
                    <li>
                      <Link to="/investor/payout-loans" className="dropdown-item">Inversiones desembolsadas</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <Link to="/wallet" className="nav-link link-bantex">
                  <img alt="Billetera" title="Billetera" src="/images/wallet.png" />
                </Link> 
              </li>

              <li className="nav-item">
                <Link to="/my-account" className="nav-link link-bantex">
                  <img alt="Mi cuenta" title="Mi Cuenta" src="/images/mi_cuenta.png" />
                </Link>
              </li>

            </React.Fragment>
            )}

          <li className="nav-item">
            <Link to="/login" onClick={handleLogout} className="nav-link link-bantex">
              <img alt="Salir" title="Salir" src="/images/salir.png" />
            </Link>
          </li>

        </ul>
      </div>
    ) : null;

  // return null;
  return (
    <React.Fragment>
      <nav 
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top" 
        style={{ minHeight: '58px', zIndex: '1' }}
        >
        <div className="container-fluid">

          <Link to="/home" className="navbar-brand">
            <img src="/images/logo.png" alt="" width="110" />
          </Link>

          <button 
            className="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>

          {navBar}

        </div>
      </nav>

      <Breadcrumbs path={breadcrumbs} />

    </React.Fragment>
  );
}

export default Header;