
function LoanAmount(props) {
  const value = props.value || 0;
  const className = props.className || null;
  const formatted = new Intl.NumberFormat(
        'en-US', 
        { style: 'currency', currency: 'USD' })
      .format( value );

  return <span className={className}>{formatted}</span>
}

export default LoanAmount;