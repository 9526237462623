import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router';
import {
  Link
} from "react-router-dom";

import { NotificationManager } from 'react-notifications';

import AuthService from '../services/AuthService';

function Login(props) {

  const loginFormInitialState = {
    username: '',
    password: ''
  };
  
  const setBreadcrumbs = props.setBreadcrumbs;
  const setShowHeader = props.setShowHeader;
  const setShowFooter = props.setShowFooter;

  const [loginForm, setLoginForm] = useState(loginFormInitialState);
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if( isMounted ){
      setShowHeader(false);
      setShowFooter(false);
      setBreadcrumbs([{title:'Iniciar sesion'}]);
    }

    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    let error = false;

    if (!form.checkValidity()) {
        error = true;
    }

    // los datos vienen del state
    // form validation

    if( error )
      form.classList.add('was-validated');

    else{
      setIsSubmiting(true);

      AuthService.login(loginForm.username, loginForm.password)
        .then(
          () => {
            setIsSubmiting(false);
            props.history.push("/home");
            window.location.reload(true);
            // NotificationManager.success('Usuario logueado con exito!');
          },
          error => {
            setIsSubmiting(false);
            // console.log(error.response);
            NotificationManager.error('Usuario/Contraseña invalidos.', 'Error');
          }
        );

    }
  }

  const handleChange = (e) => {
      const {id , value} = e.target;
      setLoginForm(prevState => ({
          ...prevState,
          [id] : value
      }));
  }

  return (
    <div className="container-fluid vh-100">
      <div className="row vh-100">
        
        <div className="col bg-light text-end" style={{ paddingTop: '190px' }}>
          <img src="/images/logo.png" alt="Bantex" width="215" />
          <h6 className="pt-2">Plataforma de Crowdlending</h6>
        </div>

        <div className="col-8 bg-bantex text-white" style={{ paddingTop: '120px' }}>

          <div className="container w-50 float-start" style={{ apaddingRight: '100px' }} >

            <h4 className="pt-4">Iniciar sesion</h4>

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">Usuario</label>
                <input 
                  type="text" 
                  className="form-control" 
                  id="username" 
                  aria-describedby="usernameHelp" 
                  onChange={handleChange}
                  value={loginForm.username} 
                  required
                  />
                {/*<div id="usernameHelp" className="form-text">Ingrese su nombre de usuario.</div>*/}
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">Contrase&ntilde;a</label>
                <input 
                  type="password" 
                  className="form-control" 
                  id="password" 
                  aria-describedby="passwdHelp" 
                  onChange={handleChange} 
                  value={loginForm.password}
                  required
                  />
                  <div id="passwdHelp" className="form-text">
                    <Link to="/recovery" className="text-white">Olvide mi contrase&ntilde;a</Link>
                  </div>
              </div>

              <div className="mb-3">

                <button 
                  type="submit" 
                  className="btn w-100 bg-bantex btn-md text-white border-white"
                  disabled={isSubmiting}
                  >
                  {isSubmiting &&
                    (
                      <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                    )}
                  Iniciar sesion
                </button>
              </div>
            </form>

            <p>No estas registrado? <Link to="/register" className="text-white">Crea tu cuenta</Link></p>          

          </div>


        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="container-fluid">
  //     <div className="row">

  //       <div className="col-lg-4 offset-lg-4 bg-light border rounded-3 mt-3 p-3">

  //         <h2>Iniciar Sesion</h2>

  //         <form onSubmit={handleSubmit}>
  //           <div className="mb-3">
  //             <label htmlFor="username" className="form-label">Usuario</label>
  //             <input 
  //               type="text" 
  //               className="form-control" 
  //               id="username" 
  //               aria-describedby="usernameHelp" 
  //               onChange={handleChange}
  //               value={loginForm.username} 
  //               required
  //               />
  //             {/*<div id="usernameHelp" className="form-text">Ingrese su nombre de usuario.</div>*/}
  //           </div>

  //           <div className="mb-3">
  //             <label htmlFor="password" className="form-label">Contrase&ntilde;a</label>
  //             <input 
  //               type="password" 
  //               className="form-control" 
  //               id="password" 
  //               aria-describedby="passwdHelp" 
  //               onChange={handleChange} 
  //               value={loginForm.password}
  //               required
  //               />
  //               <div id="passwdHelp" className="form-text">
  //                 <Link to="/recovery">Olvide mi contrase&ntilde;a</Link>
  //               </div>
  //           </div>

  //           <div className="mb-3">

  //             <button 
  //               type="submit" 
  //               className="btn w-100 bg-bantex btn-md text-white"
  //               disabled={isSubmiting}
  //               >
  //               {isSubmiting &&
  //                 (
  //                   <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
  //                 )}
  //               Iniciar sesion
  //             </button>
  //           </div>
  //         </form>

  //         <p>No estas registrado? <Link to="/register">Crear cuenta</Link></p>

  //       </div>
  //     </div>
  //   </div>
  // )
}

export default withRouter(Login);