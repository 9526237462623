import React/*, {useState, useEffect}*/ from 'react';

// import { NotificationManager } from 'react-notifications';

import LoanStatus from './LoanStatus';
import LoanAmount from './LoanAmount';
import LoanDate from './LoanDate';
import LoanId from './LoanId';
import StatusEnum from '../page/StatusEnum';

function LoanInfo(props){

  // const loanId = props.loanId; // not used
  // const loanDetailData = props.loanDetailData; // not used
  const loanData = props.loanData;

  return (
    <React.Fragment>
      <h4 
        className="d-flex flex-row align-items-center">
        Informacion de la solicitud
        {loanData && (
          <React.Fragment>
            &nbsp;-&nbsp;<LoanStatus type='image' status={loanData.status} />
          </React.Fragment>
        )}
      </h4>

      {loanData && (
      <React.Fragment>
      
        <div className="row p-1 mx-1">

          <div className="col-3 rounded border p-1">Solicitud Nro.:</div>
          <div className="col-2 rounded border bg-light p-1 text-center">
            <strong><LoanId value={loanData.id} /></strong>
          </div>

          <div className="col-3 rounded offset-1 border p-1">Fecha de alta:</div>
          <div className="col-2 rounded border bg-light p-1 text-center">
            <strong><LoanDate value={loanData.created} /></strong>
          </div>

          {/*
          <div className="col-auto">Estado:</div>
          <div className="col">
            <LoanStatus type='image' status={loanData.status} />
          </div>
          */}
        </div>

        <div className="row p-1 mx-1">
          
          <div className="col-3 rounded border p-1">Monto solicitado:</div>
          <div className="col-2 rounded border bg-light p-1 text-center">
            <strong><LoanAmount value={loanData.amount} /></strong>
          </div>

          {loanData.status === StatusEnum.APPROVED_CLIENT && (
            <React.Fragment>
              <div className="col-3 rounded offset-1 border p-1">Monto faltante:</div>
              <div className="col-2 rounded border bg-light p-1 text-center">
                <strong><LoanAmount value={loanData.amount - loanData.invested_amount} /></strong>
              </div>
            </React.Fragment>
          )}
        </div>

      </React.Fragment>
      )}
    
    </React.Fragment>
    )
}

export default LoanInfo;