
function Recovery(props) {

  return (
    <div className="container-fluid">
      <div className="row">

        <div className="col-lg-4 offset-lg-4 bg-light border rounded-3 mt-3 p-3">

          <h2>Recuperar contrase&ntilde;a</h2>

          {/*
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">Usuario</label>
              <input 
                type="text" 
                className="form-control" 
                id="username" 
                aria-describedby="usernameHelp" 
                onChange={handleChange}
                value={loginForm.username} 
                required
                />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label">Contrase&ntilde;a</label>
              <input 
                type="password" 
                className="form-control" 
                id="password" 
                aria-describedby="passwdHelp" 
                onChange={handleChange} 
                value={loginForm.password}
                required
                />
                <div id="passwdHelp" className="form-text">
                  <Link to="/recovery">Olvide mi contrase&ntilde;a</Link>
                </div>
            </div>

            <div className="mb-3">

              <button 
                type="submit" 
                className="btn w-100 bg-bantex btn-md text-white"
                disabled={isSubmiting}
                >
                {isSubmiting &&
                  (
                    <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                  )}
                Login
              </button>
            </div>
          </form>

          <p>No estas registrado? <Link to="/register">Crear cuenta</Link></p>
          */}
        </div>
      </div>
    </div>
  )
}

export default Recovery;