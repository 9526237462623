import React from 'react';

// import { NotificationManager } from 'react-notifications';
import LoanDate from './LoanDate';
import LoanAmount from './LoanAmount';
import AuthService from '../services/AuthService';

function LoanDetail(props){

  // const loanId = props.loanId; // not used
  const loanDetailData = props.loanDetailData || null;
  // const loanData = props.loanData; // not used
  const isAdmin = AuthService.getCurrentUser().type === 0; // SOLO ADMIN
  const isClient = AuthService.getCurrentUser().type === 1; // SOLO CLIENTE

  const calcLoanTotal = () => {
    let total = parseFloat(loanDetailData.loan.amount);
    total += (loanDetailData.loan.amount * loanDetailData.fee) / 100;
    total += (loanDetailData.loan.amount * loanDetailData.comission) / 100;
    return total;
  }

  const calcLoanMonthly = () => {
    return calcLoanTotal() / loanDetailData.period;
  }

  if( !loanDetailData )
    return null;

  else
    return (
      <React.Fragment>
        
        <hr />

        {loanDetailData && loanDetailData.is_reject && (
          <React.Fragment>
            <h4>Solicitud Rechazada</h4>
            <div className="row p-1 mx-1">
              <div className="col-2 rounded border p-1">Fecha:</div>
              <div className="col-3 rounded border p-1 bg-light text-center">
                <strong><LoanDate value={loanDetailData.created} /></strong>
              </div>
            </div>

            <div className="row p-1 mx-1">
              <div className="col-2 rounded border p-1">Motivo:</div>
              <div className="col rounded border p-1 bg-light text-center">
                <strong>{loanDetailData.reject_reason}</strong>
              </div>
            </div>
          </React.Fragment>
        )}

        {loanDetailData && !loanDetailData.is_reject && (
          <React.Fragment>
            <h4>Terminos del prestamo</h4>
            <div className="row p-1 mx-1">
              
              <div className="col-2 rounded border p-1">Score:</div>
              <div className="col-1 rounded border p-1 bg-light text-center">
                <strong>{loanDetailData.score}</strong>
              </div>
              <div className="col-2 offset-1 rounded border p-1">Categoria:</div>
              <div className="col-1 rounded border p-1 bg-light text-center">
                <strong>{loanDetailData.category}</strong>
              </div>
              <div className="col-2 offset-1 rounded border p-1">Periodo:</div>
              <div className="col-2 rounded border p-1 bg-light text-center">
                <strong>{loanDetailData.period} meses</strong>
              </div>
            </div>
            
            {(isClient || isAdmin) && (
              <React.Fragment>
                <div className="row p-1 mx-1">
                  <div className="col-2 rounded border p-1">Interes:</div>
                  <div className="col-2 rounded border p-1 bg-light text-center">
                    <strong>{loanDetailData.fee}%</strong>
                  </div>
                  <div className="col-2 offset-1 rounded border p-1">Comision:</div>
                  <div className="col-2 rounded border p-1 bg-light text-center">
                    <strong>{loanDetailData.comission}%</strong>
                  </div>
                </div>

                <div className="row p-1 mx-1">
                  <div className="col-3 rounded border p-1">Monto adeudado:</div>
                  <div className="col-2 rounded border p-1 bg-light text-center">
                    <strong><LoanAmount value={calcLoanTotal()} /></strong>
                  </div>
                  <div className="col-3 offset-1 rounded border p-1">Valor cuota:</div>
                  <div className="col-2 rounded border p-1 bg-light text-center">
                    <strong><LoanAmount value={calcLoanMonthly()} /></strong>
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
}

export default LoanDetail;