import React, {useState, useMemo, useEffect} from 'react';

import { NotificationManager } from 'react-notifications';

import TableBasic from './TableBasic';
import ApiService from '../services/ApiService';
import PayoutStatusEnum from '../page/PayoutStatusEnum';
import LoanDate from './LoanDate';
import LoanAmount from './LoanAmount';
import PayoutStatus from './PayoutStatus';

function LoanClientPayout(props){

  const loanId = props.loanId;
  // const loanDetailData = props.loanDetailData;
  // const loanData = props.loanData;
  // const setRefreshModal = props.setRefreshModal;

  const [isPaying, setIsPaying] = useState(false);
  const [doRefresh, setDoRefresh] = useState(false);

  const makePayment = (id) => {
    setIsPaying(true);

    ApiService.loan_pay(id)
      .then(
        (data) => {
          // console.log(data);
          setIsPaying(false);
          setDoRefresh(!doRefresh);
          NotificationManager.success(
            'Pago realizado con exito!', 'OK');
        }, error => {
          // console.log(error);
          setIsPaying(false);
          NotificationManager.error(
            'Ocurrio un error realizando el pago. Por favor intente nuevamente.', 'Error');
        });
  }

  const columnsPendings = useMemo(
    () => [
      {
        Header: '#',
        headerClassName: 'table-basic-w-id',
        cellClassName: 'align-middle',
        Cell: props => props.row.original.payout_number
      },
      {
        Header: 'Fecha',
        // headerClassName: 'table-basic-w-created',
        cellClassName: 'align-middle',
        Cell: props => <LoanDate value={props.row.original.start_date} />
      },
      {
        Header: 'Vencimiento',
        // headerClassName: 'table-basic-w-created',
        cellClassName: 'align-middle',
        Cell: props => <LoanDate value={props.row.original.start_due_date} />
      },
      {
        Header: 'Monto',
        // headerClassName: 'table-basic-w-field-1',
        cellClassName: 'align-middle',
        Cell: props => <LoanAmount value={props.row.original.amount} />
      },
      {
        Header: ' ',
        headerClassName: 'table-basic-w-actions',
        cellClassName: 'align-middle',
        Cell: props => (
          <React.Fragment>
            <div className="d-flex flex-row align-items-center">
              <PayoutStatus status={props.row.original.status} />
              {[PayoutStatusEnum.ACTIVE, PayoutStatusEnum.OVERDUE].includes(props.row.original.status) 
                && (
                <button 
                  type="button" 
                  disabled={isPaying}
                  className="btn btn-sm p-1" 
                  onClick={(e) => makePayment(props.row.original.id)}
                  >
                    {!isPaying && (
                      <img alt="Pagar" title="Pagar cuota" src="/images/cuota_pagar.png" />
                    )}

                    {isPaying && (
                      <div 
                        className="spinner-border float-end spinner-border-sm text-secondary ms-auto" 
                        role="status" aria-hidden="true"></div>
                    )}
                  </button>
              )}
            </div>
          </React.Fragment>
        )
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  const columnsPaid = useMemo(
    () => [
      {
        Header: '#',
        headerClassName: 'table-basic-w-id',
        cellClassName: 'align-middle',
        Cell: props => props.row.original.payout_number
      },
      {
        Header: 'Fecha',
        // headerClassName: 'table-basic-w-created',
        cellClassName: 'align-middle',
        Cell: props => <LoanDate value={props.row.original.paid_date} />
      },
      {
        Header: 'Monto cuota',
        // headerClassName: 'table-basic-w-field-1',
        cellClassName: 'align-middle',
        Cell: props => <LoanAmount value={props.row.original.amount} />
      },
      {
        Header: 'Monto pago',
        // headerClassName: 'table-basic-w-field-1',
        cellClassName: 'align-middle',
        Cell: props => <LoanAmount value={props.row.original.paid_amount} />
      },
      {
        Header: ' ',
        headerClassName: 'table-basic-w-actions',
        cellClassName: 'align-middle',
        Cell: props => 
          <React.Fragment>
            <div className="d-flex flex-row align-items-center">
              <PayoutStatus status={props.row.original.status} />
            </div>
          </React.Fragment>

      }
  ], []);

  return (
    <React.Fragment>
      <hr />

      <h4>Cuotas pendientes</h4>

      <LoanClientPayoutList 
        loanId={loanId} 
        status={[PayoutStatusEnum.INACTIVE, PayoutStatusEnum.ACTIVE, PayoutStatusEnum.OVERDUE]} 
        columns={columnsPendings} 
        refresh={doRefresh} />

      <hr />

      <h4>Cuotas pagas</h4>

      <LoanClientPayoutList 
        loanId={loanId} 
        status={[PayoutStatusEnum.PAID, PayoutStatusEnum.OVERDUE_PAID]} 
        columns={columnsPaid} 
        refresh={doRefresh} />

    </React.Fragment>
  );
}

function LoanClientPayoutList(props){

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [data, setData] = useState([]);

  const loanId = props.loanId;
  const statusToShow = props.status;
  const columns = props.columns;
  const refresh = props.refresh

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){
      ApiService.loan_payout(loanId, statusToShow)
        .then(
          (data) => {
            // console.log(data);
            setIsLoadingData(false);
            setData(data);
          }, error => {
            // console.log(error);
            setIsLoadingData(false);
            NotificationManager.error(
              'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
          });
    }
    
    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const memoData = useMemo(
    () => data, [data]
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <TableBasic  
            className="table table-hover" 
            isLoading={isLoadingData} 
            columns={columns} data={memoData} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default LoanClientPayout;
