import React, {useState, useMemo, useEffect} from 'react';

import { NotificationManager } from 'react-notifications';

import TableBasic from './TableBasic';
import ApiAdminService from '../services/ApiAdminService';
import LoanDate from './LoanDate';
import LoanAmount from './LoanAmount';
import PayoutStatus from './PayoutStatus';

function LoanStatusPayout(props){

  const loanId = props.loanId;
  // const loanDetailData = props.loanDetailData;
  // const loanData = props.loanData;
  // const setRefreshModal = props.setRefreshModal;

  const columnsPendings = useMemo(
    () => [
      {
        Header: '#',
        headerClassName: 'table-basic-w-id',
        cellClassName: 'align-middle',
        Cell: props => props.row.original.payout_number
      },
      {
        Header: 'Fecha',
        // headerClassName: 'table-basic-w-created',
        cellClassName: 'align-middle',
        Cell: props => <LoanDate value={props.row.original.start_date} />
      },
      {
        Header: 'Vencimiento',
        // headerClassName: 'table-basic-w-created',
        cellClassName: 'align-middle',
        Cell: props => <LoanDate value={props.row.original.start_due_date} />
      },
      {
        Header: 'Monto',
        // headerClassName: 'table-basic-w-field-1',
        cellClassName: 'align-middle',
        Cell: props => <LoanAmount value={props.row.original.amount} />
      },
      {
        Header: 'Monto Pago',
        // headerClassName: 'table-basic-w-field-1',
        cellClassName: 'align-middle',
        Cell: props => <LoanAmount value={props.row.original.paid_amount} />
      },
      {
        Header: ' ',
        headerClassName: 'table-basic-w-actions',
        cellClassName: 'align-middle',
        Cell: props => (
          <React.Fragment>
            <div className="d-flex flex-row align-items-center">
              <PayoutStatus status={props.row.original.status} />
            </div>
          </React.Fragment>
        )
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  return (
    <React.Fragment>
      <hr />

      <h4>Detalle de cuotas</h4>

      <LoanPayoutStatusList 
        loanId={loanId} 
        columns={columnsPendings} />

    </React.Fragment>
  );
}

function LoanPayoutStatusList(props){

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [data, setData] = useState([]);

  const loanId = props.loanId;
  const columns = props.columns;

  useEffect( () => {
    let isMounted = true;
    
    if( isMounted ){
      ApiAdminService.loan_payout_status(loanId)
        .then(
          (data) => {
            // console.log(data);
            setIsLoadingData(false);
            setData(data);
          }, error => {
            // console.log(error);
            setIsLoadingData(false);
            NotificationManager.error(
              'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
          });
    }
    
    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoData = useMemo(
    () => data, [data]
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <TableBasic  
            className="table table-hover" 
            isLoading={isLoadingData} 
            columns={columns} data={memoData} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default LoanStatusPayout;
