function LoanRequestReason(props) {

  const reason = props.reason;
  const reason_text = {
    1:  "Refinanciamiento de tarjeta de credito",
    2:  "Consolidacion de deudas",
    3:  "Mejoras en el hogar",
    4:  "Adiciones de infraestructuras en el hogar",
    5:  "Financiamiento de vivienda (detallar)",
    6:  "Financiamiento del automovil (detallar)",
    7:  "Financiamiento para negocios (detallar)",
    8:  "Vacaciones",
    9:  "Mudanza y reubicacion",
    10: "Gastos medicos",
    11: "Otros (detallar)",
  }

  return (
    <span>{reason_text[reason]}</span>
  );
}

export default LoanRequestReason;