function PayoutStatus(props) {
  // const type = props.type || 'icon';
  const status = props.status || 0;
  const className = props.className || '';
  const statusStr = [
    '',
    { label:'INACTIVA',     image: 'cuota_inactiva.png' },  //1
    { label:'ACTIVA',       image: 'cuota_activa.png' },  //2
    { label:'EN MORA',      image: 'cuota_mora.png' },     //3
    { label:'PAGADA',       image: 'cuota_paga.png' },    //4
    { label:'PAGADA MORA',  image: 'cuota_paga_mora.png' }     //5
  ];

  if( status > 0 && status < statusStr.length ){
    // return <span>{statusStr[status].label}</span>;
    let image = null;

    // if( type === 'icon' )
    //   image = (
    //     <img  width="25" height="25"
    //       alt={statusStr[status].label} 
    //       title={statusStr[status].label} 
    //       className={className}
    //       src={'/images/' + statusStr[status].icon} />);
    // else 
      image = (
        <img 
          alt={statusStr[status].label} 
          title={statusStr[status].label} 
          className={className}
          src={'/images/' + statusStr[status].image} />);

    return image;
  }else
    return null;
}

export default PayoutStatus;