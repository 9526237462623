import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import ApiAdminService from '../services/ApiAdminService';

function LoanActionPayout(props){

  const loanId = props.loanId;
  // const loanDetailData = props.loanDetailData;
  // const loanData = props.loanData;
  const setRefreshModal = props.setRefreshModal;

  return (
    <React.Fragment>

      <div className="row">
        <div className="col offset-6 text-center">
          
          <hr />
          
          <h5 className="text-center">Desembolsar</h5>

          <LoanActionPayoutButton 
            loanId={loanId} 
            setRefreshModal={setRefreshModal}
            />

        </div>
      </div>

    </React.Fragment>
  );
}

function LoanActionPayoutButton(props){

  const loanId = props.loanId;
  const setRefreshModal = props.setRefreshModal;

  const [isWorking, setIsWorking] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const makePayout = () => {
    setIsWorking(true);
    setDisabled(true);

    ApiAdminService.loan_payout(loanId)
      .then(
        (data) => {
          setIsWorking(false);
          setRefreshModal(true);

          NotificationManager.success(
            'Se realizo el desembolso correctamente.', 'OK');

        }, error => {
          setDisabled(false);
          setIsWorking(false);
          NotificationManager.error(
            'Ocurrio un error realizando el desembolso. Por favor intente nuevamente.', 'Error');
        });
  }

  return (
    <button 
      type="button" 
      className="btn bg-bantex text-white w-75" 
      onClick={makePayout} 
      disabled={disabled} 
      >
        Aceptar&nbsp;&nbsp;
        {isWorking && (
          <span 
            className="spinner-border spinner-border-sm mx-2" 
            role="status" aria-hidden="true"></span>
        )}
      </button>
  );
}

export default LoanActionPayout;
