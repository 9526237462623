import './App.css';
import React, { useState } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

import axios from "axios";
import { isExpired } from "react-jwt";

import AuthService from './services/AuthService';
import StatusEnum from './page/StatusEnum';

import Header from './page/Header';
import Footer from './page/Footer';

import Login from './content/Login';
import RegisterForm from './content/RegisterForm';
import Home from './content/Home';
import MyAccount from './content/MyAccount';
import Recovery from './content/Recovery';

// investor
import InvestorLoans from './content/investor/InvestorLoans';
import InvestedLoans from './content/investor/InvestedLoans';
import InvestedLoansPayout from './content/investor/InvestedLoansPayout';

// client
import ClientLoans from './content/client/ClientLoans';
import LoanForm from './content/client/LoanForm';

//admin
import AdminLoans from './content/admin/AdminLoans';



axios.interceptors.request.use( (config) => {

  let logged_user = AuthService.getCurrentUser();

  if( logged_user ){
    const access_token = logged_user.access_token || null;

    if( access_token && isExpired(access_token) ){
      AuthService.logout();
      window.location.reload(true)
    }

    if( access_token )
      config.headers.Authorization = 'Bearer ' + access_token
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use( (response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response;
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // console.log(error);
  // console.log(error.response);

  if( error.response 
      && !error.response.request.responseURL.includes('/auth/') 
      && (error.response.status === 401 || error.response.status === 403) ){
    AuthService.logout();
    window.location.reload(true)
  }

  return Promise.reject(error);
});

// TODO: Agregarle la opcion de que solo permita verlo a un tipo de usuairo
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest} 
      render={({ location }) =>
        AuthService.getCurrentUser() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function ClientRoute({ children, ...rest }) {
  return (
    <Route
      {...rest} 
      render={({ location }) =>
        AuthService.getCurrentUser() && AuthService.getCurrentUser().type === 1 ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function InvestorRoute({ children, ...rest }) {
  return (
    <Route
      {...rest} 
      render={({ location }) =>
        AuthService.getCurrentUser() && AuthService.getCurrentUser().type === 2 ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function AdminRoute({ children, ...rest }) {
  return (
    <Route
      {...rest} 
      render={({ location }) =>
        AuthService.getCurrentUser() && AuthService.getCurrentUser().type === 0 ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function App() {

  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);

  return (
    <BrowserRouter>

      {showHeader && (
        <Header breadcrumbs={breadcrumbs} />
      )}
      
      <main 
        className="App overflow-auto" 
        style={{
          height: 'calc(100% - ' + (showFooter ? '32' : '0') + 'px - ' + (showHeader ? '83' : '0') + 'px)',
          marginTop: showHeader ? '83px' : '', 
          // marginBottom: showFooter ? '38px' : ''
          // paddingTop: showHeader ? '83px' : '', 
          // paddingBottom: showFooter ? '38px' : '' 
        }}
        > {/*HEADER & FOOTER*/}

        <Switch>
          <Route path="/login">
            <Login 
              setBreadcrumbs={setBreadcrumbs} 
              setShowHeader={setShowHeader} 
              setShowFooter={setShowFooter} />
          </Route>

          <Route path="/register">
            <RegisterForm 
              setBreadcrumbs={setBreadcrumbs} 
              setShowHeader={setShowHeader} 
              setShowFooter={setShowFooter} />
          </Route>

          <Route path="/recovery">
            <Recovery 
              setShowHeader={setShowHeader} 
              setShowFooter={setShowFooter} />
          </Route>

          <PrivateRoute path="/home">
            <Home 
              setBreadcrumbs={setBreadcrumbs} 
              />
          </PrivateRoute>

          <PrivateRoute path="/my-account">
            <MyAccount 
              setBreadcrumbs={setBreadcrumbs} 
              />
          </PrivateRoute>


          {/* -------------------- CLIENT ROUTES ----------------------- */}
          <ClientRoute path="/client/loan/create">
            <LoanForm setBreadcrumbs={setBreadcrumbs} />
          </ClientRoute>

          <ClientRoute path="/client/requests">
            <ClientLoans 
              status={[
                StatusEnum.PENDING_BANTEX, StatusEnum.PENDING_CLIENT,
                StatusEnum.REJECTED_BANTEX, StatusEnum.REJECTED_CLIENT
                ]} 
              title={"Mis solicitudes"}
              setBreadcrumbs={setBreadcrumbs} 
              />
          </ClientRoute>

          <ClientRoute path="/client/approved-loans">
            <ClientLoans 
              status={[StatusEnum.APPROVED_CLIENT]}
              title={"Prestamos aprobados"}
              setBreadcrumbs={setBreadcrumbs} 
              />
          </ClientRoute>

          <ClientRoute path="/client/loans">
            <ClientLoans 
              status={[StatusEnum.PAYOUT]}
              title={"Prestamos desembolsados"}
              setBreadcrumbs={setBreadcrumbs} 
              />
          </ClientRoute>


          {/* -------------------- INVESTOR ROUTES ----------------------- */}
          <InvestorRoute path="/investor/loans">
            <InvestorLoans 
              setBreadcrumbs={setBreadcrumbs} 
              />
          </InvestorRoute>

          <InvestorRoute path="/investor/invested-loans">
            <InvestedLoans 
              setBreadcrumbs={setBreadcrumbs} 
              />
          </InvestorRoute>

          <InvestorRoute path="/investor/payout-loans">
            <InvestedLoansPayout 
              setBreadcrumbs={setBreadcrumbs} 
              />
          </InvestorRoute>



          {/* -------------------- ADMIN ROUTES ----------------------- */}
          <AdminRoute path="/admin/loans">
            <AdminLoans setBreadcrumbs={setBreadcrumbs} />
          </AdminRoute>


          <Route path="*">
            {AuthService.getCurrentUser() && (
              <Redirect
                to={{
                  pathname: "/home",
                }} />
            )}
            {!AuthService.getCurrentUser() && (
              <Redirect
                to={{
                  pathname: "/login",
                }} />
            )}
          </Route>
        </Switch>
      </main>

      {showFooter && (
        <Footer />
      )}

      <NotificationContainer />
    </BrowserRouter>
  );
}

export default App;
