import React, {useState, useEffect} from 'react';

import { NotificationManager } from 'react-notifications';

import LoanPeriodSelect from '../components/LoanPeriodSelect';
import ApiAdminService from '../services/ApiAdminService';

function LoanAction(props) {

  const loanId = props.loanId;
  // const loanDetailData = props.loanDetailData;
  const loanData = props.loanData;
  const setRefreshModal = props.setRefreshModal;

  // const actionDisabled = loanDetailData ? true : false;

  return (
    <React.Fragment>
      <form id="loanActionForm">

        <LoanActionApprove 
          loanId={loanId}
          setRefreshModal={setRefreshModal} 
          // disabled={actionDisabled} 
          loanData={loanData}
          // loanDetail={loanDetailData}
          />

        <LoanActionReject 
          loanId={loanId}
          setRefreshModal={setRefreshModal}
          // disabled={actionDisabled}
          loanData={loanData}
          // loanDetail={loanDetailData}
          />

      </form>
    </React.Fragment>
    )
}

/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////


function LoanActionApprove(props) {

  const loanId = props.loanId;
  // const loanData = props.loanData; // not used
  // const loanDetail = props.loanDetail;
  const setRefreshModal = props.setRefreshModal;
  // const disabled = props.disabled;

  const formDataInitialValue = {
    loan: loanId,
    score: null,
    category: null,
    fee: null,
    comission: null,
    period: null,
    reject_reason: null, // estos dos valores no nos interesan desde este componente
    is_reject: false // estos dos valores no nos interesan desde este componente
  }
  const [isLoadingPlan, setIsLoadingPlan] = useState(false);
  const [formData, setFormData] = useState(formDataInitialValue);

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // useEffect( () => {
  //   if( loanDetail )
  //     setFormData(loanDetail);
  // }, [loanDetail]);

  useEffect( () => {

    if( formData 
      && (0 < formData.score < 10.1)
      && formData.period > 0 )
      setDisableSubmit(false);
    else
      setDisableSubmit(true);

  }, [formData]);

  const calcScore = (score) => {

    score = (score > 0 && score <= 10) ? score : 0;

    if( score ){
      setIsLoadingPlan(true);

      ApiAdminService.loan_plan_info(score)
        .then(
          (data) => {
            setIsLoadingPlan(false);

            if( data.results[0] ){
              setFormData( prevState => ({
                ...prevState,
                category:  data.results[0].category,
                fee:       data.results[0].fee,
                comission: data.results[0].comission,
                score:     data.results[0].score,
              }));

            }else{
              setFormData(formDataInitialValue);
            }

          }, error => {
            setIsLoadingPlan(false);
            setFormData(formDataInitialValue);
            NotificationManager.error(
              'Ocurrio un error obteniendo la informacion. Por favor intente nuevamente.', 'Error');
          });
    }
  }

  const submitProposal = () => {
    // console.log(loanPlanData);
    setDisabled(true);
    setIsSubmittingData(true);

    ApiAdminService.create_plan_detail(formData)
      .then(
        (data) => {
          setIsSubmittingData(false);
          setRefreshModal(true);

          NotificationManager.success(
            'Se envio correctamente la propuesta.', 'OK');

        }, error => {
          setDisabled(false);
          setIsSubmittingData(false);
          NotificationManager.error(
            'Ocurrio un error enviando la propuesta. Por favor intente nuevamente.', 'Error');
        });
  }

  return (
    <React.Fragment>
      <hr />

      <h4>Enviar Propuesta</h4>

      <div className="row p-1 m-1">

        <label htmlFor="score" className="col-2 col-form-label">Score:</label>
        <div className="col-3">
          <div className="input-group">
            <input 
              type="number" 
              min="0.1" max="10.0" step="0.1" 
              className="form-control text-center" 
              id="score" 
              value={formData.score || ''}
              disabled={disabled}
              onBlur={() => calcScore(formData.score)}
              onChange={(e) => setFormData({...formData, score: e.target.value})} />

              {isLoadingPlan && (
                <span className="input-group-text" id="basic-addon2">
                  <div 
                    className="spinner-border float-end spinner-border-sm text-secondary ms-auto" 
                    role="status" aria-hidden="true"></div>
                </span>
              )}
          </div>
        </div>

        <label htmlFor="category" className="col-2 offset-1 col-form-label">Categoria:</label>
        <div className="col-3">
          <div className="input-group">
            <input 
              disabled 
              type="text" 
              className="form-control text-center" 
              id="category" 
              value={formData.category || 'N/A'} />
          </div>
        </div>

      </div>

      <div className="row p-1 m-1">

        <label htmlFor="fee" className="col-2 col-form-label">Interes:</label>
        <div className="col-3">
          <div className="input-group">
            <div className="input-group-text">%</div>
            <input 
              disabled 
              type="text" 
              className="form-control text-center" 
              id="fee" 
              value={formData.fee || 'N/A'} />
          </div>
        </div>

        <label htmlFor="comission" className="col-2 offset-1 col-form-label">Comision:</label>
        <div className="col-3">
          <div className="input-group">
            <div className="input-group-text">%</div>
            <input 
              disabled 
              type="text" 
              className="form-control text-center" 
              id="comission" 
              value={formData.comission || 'N/A'} />
          </div>
        </div>

      </div>

      <div className="row p-1 m-1">

        <label htmlFor="period" className="col-2 col-form-label">Plazo:</label>
        <div className="col-3">
          <div className="input-group">
            <LoanPeriodSelect 
              value={formData.period || ''} 
              disabled={disabled}
              onChange={(value) => setFormData({...formData, period: value})} />
          </div>
        </div>

        <div className="col-5 offset-1">
          <button 
            type="button" 
            className="btn bg-bantex text-white w-100"
            disabled={disabled || disableSubmit}
            onClick={submitProposal} 
            >
            Enviar propuesta&nbsp;
            {isSubmittingData && (
              <span 
                className="spinner-border spinner-border-sm mx-2" 
                role="status" aria-hidden="true"></span>
            )}
            </button>
        </div>

      </div>
    </React.Fragment>
  )
}


/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////



function LoanActionReject(props){

  const loanId = props.loanId;
  // const loanData = props.loanData; // not used
  // const loanDetail = props.loanDetail;
  const setRefreshModal = props.setRefreshModal;
  // const disabled = props.disabled;

  const [disabled, setDisabled] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const loanPlanDataInitialValue = {
    loan: loanId,
    score: null,
    category: null,
    fee: null,
    comission: null,
    period: null,
    reject_reason: 'Motivo',
    is_reject: true
  }

  const rejectLoan = () => {
    setIsRejecting(true);
    setDisabled(true);

    // TODO: CONFIRMACION
    ApiAdminService.create_plan_detail(loanPlanDataInitialValue)
      .then(
        (data) => {
          setIsRejecting(false);
          setRefreshModal(true);

          NotificationManager.success(
            'Se rechazo correctamente la solicitud.', 'OK');

        }, error => {
          setDisabled(false);
          setIsRejecting(false);
          NotificationManager.error(
            'Ocurrio un error rechazando la solicitud. Por favor intente nuevamente.', 'Error');
        });
  }

  return (
    <React.Fragment>
      <hr />

      <h4>Rechazar Solicitud</h4>

      <div className="row p-1 m-1">

        <div className="col-7">
          <div className="input-group">
            <select 
              className="form-select"
              disabled={disabled} 
              >
              <option value="1">Motivo 1</option>
              <option value="1">Motivo 2</option>
              <option value="1">Motivo 3</option>
              <option value="1">Motivo 4</option>
              <option value="1">Motivo 5</option>
            </select>
          </div>
        </div>

        <div className="col-4">
          <button 
            type="button" 
            className="btn btn-danger w-100"
            onClick={rejectLoan} 
            disabled={disabled} 
            >
              Rechazar solicitud&nbsp;
              {isRejecting && (
                <span 
                  className="spinner-border spinner-border-sm mx-2" 
                  role="status" aria-hidden="true"></span>
              )}
            </button>
        </div>

      </div>
    </React.Fragment>
  )
}

export default LoanAction;